import React from "react";
// import logo from './logo.svg';
import "./App.scss";
import ScrollToTop from "./components/scroll-to-top/scroll-to-top";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes";

import Typography from "@material-ui/core/Typography";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  spacing: 1,
  typography: {
    fontSize: 24,
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <Header></Header>
        <div id="content">
          <div
            style={{
              background: "white",
            }}
          >
            <div className="alert alert-info text-left covid-disclaimer">
              <p style={{ textAlign: "center" }}>
                <strong>
                  <i className="fa fa-exclamation-triangle"></i>
                  &nbsp;&nbsp;Please Note
                </strong>
              </p>
              <p style={{ textAlign: "center" }}>
                Health couch is not currently taking bookings for new clients.
              </p>
            </div>
          </div>
          <AppRoutes />
        </div>
        <Footer></Footer>
      </Router>
    </ThemeProvider>
  );
};

export default App;
