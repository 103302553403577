import React from "react";

const ConsentForm = ({ signPageUrl }: { signPageUrl: string }) => {
  return (
    <>
      <iframe
        src={signPageUrl}
        id="eSignaturesIOIframe"
        style={{
          width: "1%",
          minHeight: 600,
          height: "100%",
          minWidth: "100%",
        }}
      ></iframe>
    </>
  );
};

export default ConsentForm;
