import React, { useState } from 'react';
import './dropdown.scss'
import DropdownOption from './dropdown-option';

const Dropdown = ({options, onChange}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  onChange = onChange ? onChange : () => {};
  return (
    <div className="dropdown">
      <div className="theme_button color1 toggle" onClick={(e) => setIsOpen(!isOpen)}>
        {selectedOption ? selectedOption : "All"}
        {isOpen ? 
          <i className="fa fa-caret-up"></i> : 
          <i className="fa fa-caret-down"></i>
        }
      </div>

      <ul className={!isOpen ? 'expandable closed': 'expandable'}>
        {options.map(option => 
          <DropdownOption value={option.value} onSelect={(e) => {setSelectedOption(e); setIsOpen(false); onChange(e)}} >{option.name}</DropdownOption>
        )}
      </ul>
    </div>
  );
};

export default Dropdown;