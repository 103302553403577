import React from 'react';
import './contact.scss';
import Section from '../../components/section/section'
import { Helmet } from 'react-helmet'
// import ContactDetails from './contact-details';
function ContactThanks() {
  return (
    <div>
      <Helmet>
        <title>Health Couch Psychology | Enquiry Received</title>
        <meta name="description" content="We have received your enquiry."></meta>
      </Helmet>
      <Section class="ls section_padding_bottom_40">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="section_header">Thanks!</h2>
              <p>
                We've received your enquiry
              </p>
            </div>
            <div className="col-sm-12 text-center clearfix">
              <p className="lead">
                We have received your enquiry and will get back to you as soon as possible.
              </p>
            </div>
            
            <div style={{paddingTop: 0}} className="text-center">
              <i className="highlight fa fa-phone"></i>
              <div><a href="tel:0416057570">0416 057 570</a></div>
            </div>

            <div style={{marginTop: 30}} className="text-center">
              <i className="highlight fa fa-envelope-o"></i>
              <div><a href="mailto:help@health-couch.com.au">help@health-couch.com.au</a></div>
            </div>

            <div style={{marginTop: 30}} className="text-center">
              <i className="highlight fa fa-map-marker"></i>
              <div>Suite 2, 5-7 Chandler Rd Boronia, Victoria 3155</div>
            </div>
          
          </div>
        </div>
      </Section>
      
    </div>
  );
}

export default ContactThanks;
