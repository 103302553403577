import React from 'react';
import { Fragment } from 'react';
import Section from '../../components/section/section'
import { Helmet } from 'react-helmet'
import CallToAction from '../../components/call-to-action/call-to-action';

function Hypnotherapy() {
  return (
    <Fragment>
      <Helmet>
        <title>Using the sub-conscious to improve therapy outcomes | Health Couch Psychology</title>
        <meta name="description" content="Clinical Hypnotherapy combines hypnosis with psychotherapy. Hypnosis is a nautrally occuring 
          state of concentration similar to daydreaming. Go back to the issue that’s causing you problems, put it in pause, and 
          imagine a different reality."></meta>
      </Helmet>
      <Section class="ls section_padding_top_25 section_padding_bottom_60">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="section_header">Clinical Hypnotherapy</h2>
              {/* <p>Anxiety, Depression and Stress</p> */}
            </div>
            <div className="col-sm-12">
              <p>Clinical Hypnotherapy is a combination of hypnosis and psychotherapy. </p>
              <p> Hypnosis is a naturally-occurring state of concentration similar to daydreaming. It involves moving past conscious thinking, and
              relaxing mentally and physically. When you are in a hypnotic state you are able to focus your attention, access parts of your subconscious
                  mind such as memories, thoughts, and dream-like states, and you become more responsive to suggestion and direction. </p>
              <p> In Clinical Hypnotherapy we use this trance like state to improve therapy outcomes. For example we might go back to the issue that’s causing
                  you problems, putting it in pause, and we imagine a different reality, or go back to the state before you felt pain and reactivate it. </p>
              <p> If Clinical Hypnotherapy is considered as a treatment option, we will further discuss it in your appointment. This includes explaining what is
              involved and the psychologist’s qualifications and experience in conducting hypnosis, and clarifying anything about hypnosis and how it fits
                  with overall therapy. Your well-being and dignity are essential and any concerns will be addressed before instigating therapy. </p>
              <p>More information is at:</p>
              <ul>

                <li><a target="_new" href="https://www.hypnotherapycouncilofaustralia.com/"> Hypnotherapy Council of Australia </a></li>
                <li><a target="_new" href="https://pcha.com.au/"> Professional Clinical Hypnotherapists of Australia </a></li>
              </ul>
            </div>
          </div>
        </div>
      </Section>

      <CallToAction />
      {/* <Resources></Resources> */}

    </Fragment>
  );
}
export default Hypnotherapy