import React, { Fragment } from 'react';
import {Link} from 'react-router-dom';
import './problem.scss'
const Problem = ({icon, title, subtitle, link}) => {
  
  return (
    <Fragment>
      <div className="col-md-3 col-sm-6 problem">
        <Link to={link}>
          <div className="with_padding text-center teaser hover_shadow">
            <img className="problem-icon" alt={title} src={icon}/>
            <h4>
              {title}
            </h4>
            <p>
              {subtitle}
            </p>
          </div>
        </Link>
      </div>
    </Fragment>
  );
}
export default Problem;
