import React from 'react';
import { Fragment } from 'react';
import Section from '../../components/section/section'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import CallToAction from '../../components/call-to-action/call-to-action';

function ChildAndAdolescent() {
  return (
    <Fragment>
      <Helmet>
        <title>Experienced child and adolescent therapist | Health Couch Psychology</title>
        <meta name="description" content="Principal psychologist Susan Henderson is a retired school teacher 
          with 30+ years of experience working with children and adolescents"></meta>
      </Helmet>
      <Section class="ls section_padding_top_25 section_padding_bottom_60">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="section_header">Child and adolescent therapy</h2>
              {/* <p>Anxiety, Depression and Stress</p> */}
            </div>
            <div class="col-sm-12">
              <p>
                In addition to being an experienced psychologist, our principal psychologist Susan Henderson
                is a retired primary school teacher and school counsellor with 30+ years of experience working with children and adolescents.
              </p>
              <p>Parents and caregivers are involved in treatment. Specifics of involvement are discussed between the psychologist and parents/caregivers.</p>
              <p>
                Using therapies like Art and Play Therapy, <Link to="/therapies/emdr">EMDR</Link> and <Link to="/therapies/psychotherapy">other psychotherapies</Link>
                Health Couch helps with symptoms of:
              </p>
              <ul>
                <li>anxiety and depression </li>
                <li>trouble focusing</li>
                <li>poor school performance</li>
                <li>bed-wetting</li>
                <li>behavioural issues</li>
                <li>withdrawal</li>
                <li>self-harm</li>
              </ul>
              <p>Health Couch also uses EMDR with children. The effectiveness is less well established in research than with adults.
                If EMDR is considered for your child, this will be discussed with you further before instigating any therapy. </p>
            </div>
          </div>
        </div>
      </Section>

      <CallToAction />
      {/* <Resources></Resources> */}

    </Fragment>
  );
}
export default ChildAndAdolescent