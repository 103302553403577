  
import React, {Fragment} from 'react';
import InputMask from 'react-input-mask';

const Medicare = ({register, errors, watch, setValue}) => {
  const medicare_intend_to_claim = watch("medicare_intend_to_claim");
  const medicare_number = watch("medicare_number");
  // const [medicareNumber, setMedicareNumber] = useState(null);
  // let medicareNumber = '';
  
  // console.log('initialiiy, medicare_number is', medicare_number);

  const handleOnMedicareChange = (ev) => {
    let newValue = ev.target.value || "";
    // console.log(newValue)
    newValue = newValue.replace(/\s/g, '');
    setValue('medicare_number', newValue);
    // setMedicareNumber(newValue);
    // console.log('medicare number', newValue);
  }

  return ( 
    <div className="col-sm-12">
      <h2>Your Medicare Details</h2>
      <div className="form-group">
        <label htmlFor="medicare_intend_to_claim">Are you intending to claim medicare for this or future appointments with Health Couch?</label>
        <select className="form-control" name="medicare_intend_to_claim" id="medicare_intend_to_claim" ref={register({ required: true })}>
          <option value="">- Please select</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
        <div className="error">{errors.medicare_intend_to_claim ? 'Please select yes/no' : ''}</div>
      </div>
      { medicare_intend_to_claim === "Yes" ? (
      <Fragment>
      {/* <div className="form-group">
        <p>Criteria for patient's considered to be 'at risk of COVID-19' includes:</p>
        <ul>
          <li>required to self-isolate or self-quarantine in accordance with guidance issued by the Australian Health Protection Principal Committee in relation to COVID-19; </li>
          <li>at least 70 years old; </li>
          <li>identify as being of Aboriginal or Torres Strait Islander descent—and are at least 50 years old;</li>
          <li>pregnant;</li>
          <li>the parent of a child aged under 12 months;</li>
          <li>being treated for a chronic health condition;</li>
          <li>immune compromised;</li>
          <li>meet the current national triage protocol criteria for suspected COVID-19 infection.</li>
        </ul>
        <label htmlFor="medicare_intend_to_claim">Do you meet any of the above criteria for ‘at risk of COVID-19’?</label>
        <select className="form-control" name="medicare_covid_19_at_risk" id="medicare_covid_19_at_risk" ref={register({ required: medicare_intend_to_claim === "Yes" })}>
          <option value="">- Please select</option> 
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
        <div className="error">{errors.medicare_covid_19_at_risk ? 'Please select an option.' : ''}</div>
      </div> */}
      {/* <div className="form-group">
        <label htmlFor="medicare_concession_seniors_health_card">Do you have any of the following cards?</label>
        <ul>
          <li>a pensioner concession card</li>
          <li>a seniors health card </li>
          <li>a health care card under the Social Security Act 1991.</li>
        </ul>
        <select className="form-control" name="medicare_concession_seniors_health_card" id="medicare_concession_seniors_health_card" ref={register({ required: medicare_intend_to_claim === "Yes" })}>
          <option value="">- Please select</option> 
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
        <div className="error">{errors.medicare_concession_seniors_health_card ? 'Please select an option.' : ''}</div>
      </div> */}
      {/* <div className="form-group">
        <label htmlFor="medicare_under_16">Is this appointment for a child under the age of 16?</label>
        <select className="form-control" name="medicare_under_16" id="medicare_under_16" ref={register({ required: medicare_intend_to_claim === "Yes" })}>
          <option value="">- Please select</option> 
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
        <div className="error">{errors.medicare_under_16 ? 'Please select an option.' : ''}</div>
      </div> */}
      {/* <div className="form-group">
        <label htmlFor="medicare_eligibility_consent">Do you consent to Health Couch's Principal Psychologist Susan Henderson (provider number 2832468f) performing a search in HPOS to confirm your eligibility to claim?</label>
        <select className="form-control" name="medicare_eligibility_consent" id="medicare_eligibility_consent" ref={register({ required: medicare_intend_to_claim === "Yes" })}>
          <option value="">- Please select</option> 
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
        <div className="error">{errors.medicare_eligibility_consent ? 'Please note: we require your consent if you wish to claim a medicare rebate.' : ''}</div>
      </div> */}
      <div className="form-group">
        <label htmlFor="medicare_number">What is your medicare number?</label>
        <InputMask type="text" mask="9999 99999 9" maskChar=" " autoComplete={"no-autocomplete"} onChange={handleOnMedicareChange} value={medicare_number}/>
        <input type="hidden" name="medicare_number" ref={register({ required: medicare_intend_to_claim === "Yes", minLength: 10, maxLength: 10})}/>
        
        <div className="error">{errors.medicare_number ? 'Please enter a valid medicare number' : ''}</div>
      </div>
      <div className="form-group">
        <label htmlFor="medicare_reference_number">What is your medicare reference number?</label>
        <input type="number" id="medicare_reference_number" maxLength="2" name="medicare_reference_number" ref={register({ required: medicare_intend_to_claim === "Yes", minLength: 1, maxLength: 2 })} autoComplete={"medicare_reference_number"}/>
        <div className="error">{errors.medicare_reference_number ? 'Please enter your medicare reference number' : ''}</div>
      </div>
      </Fragment>
      ) : '' }
    </div>
  );
}

export default Medicare;