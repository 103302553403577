import React from 'react';

const CancellationIfYou = () => (
  <>
    <p>
      If you:
    </p>
    <ul>
      <li>cancel your appointment with more than 24 hours notice, your deposit is refundable (i.e. you will not be charged).</li>
      <li>cancel your appointment with less than 24 hours notice, your deposit will be forfeit (i.e. you will be charged $100).</li>
      <li>do not provide notice of cancellation and fail to show for your appointment, you will be charged the full appointment fee.</li>
    </ul>
  </>
)

const CancellationPolicy = () => (
  <>
    <p>
      If you need to cancel or postpone your appointment, please give the psychologist at least 24 hours notice 
      so that another person on the cancellation list might have that appointment. 
    </p>
    <CancellationIfYou/>
  </>
)

export default CancellationPolicy;
export {CancellationIfYou};