import React from 'react';

const FaqItem = ({title, children, href}) => {
  return (
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          <a data-toggle="collapse" data-parent="#accordion1" href={'#'+href} class="collapsed">
            {title}
          </a>
        </h4>
      </div>
      <div id={href} class="panel-collapse collapse">
        <div class="panel-body">
          {children}
        </div>
      </div>
    </div>
  )
};

export default FaqItem;