import React from 'react';
import {Fragment} from 'react';
// import slide1 from "../../slide02.jpg";
// import beach from './images/beach.jpg'
import couch from './images/couch.jpg'
// import arms from './images/raised-arms.jpg'
import './intro-section.scss'

function IntroSection(props){
  let {title, subtitle} = props;
  title = title ? title : 'Health Couch';
  subtitle = subtitle ? subtitle : 'Telehealth psychology, EMDR and hypnotherapy';
  // subtitleMobile = subtitleMobile ? subtitleMobile : 'Telehealth psychology, EMDR and hypnotherapy';
  return (
    <Fragment>
      {/* <div className="big-image hidden-xs">
        <img alt={title} src={slide1} />
        <div className="text-wrapper">
          <div>
            <h3 className="hidden-xs">{title}</h3>
            <h3 className="mobile-small-text hidden-sm hidden-md hidden-lg">{title}</h3>
            <p className="small-text hidden-xs">{subtitle}</p>
            <p className="mobile-small-text hidden-sm hidden-md hidden-lg">{subtitleMobile}</p>
          </div>
        </div>
      </div> */}
      <div className="big-image">
        <img alt={title} src={couch} />
        <div className="text-wrapper">
          <div>
            <h3 className="">{title}</h3>
            <p className="">{subtitle}</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default IntroSection;