import React from 'react';
import { Fragment } from 'react';
import Section from '../../components/section/section'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import CallToAction from '../../components/call-to-action/call-to-action';

const AnxietyAndDepression = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Treat anxiety, depression, and stress | Health Couch Psychology</title>
        <meta name="description" content="Process threats and get your brain out of this damaging loop"></meta>
      </Helmet>
      <Section class="ls section_padding_top_25 section_padding_bottom_60">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="section_header">Anxiety, depression and stress</h2>
              {/* <p>Anxiety, Depression and Stress</p> */}
            </div>
            <div class="col-sm-12">
              <p>
                We use psychological therapies that follow the trauma model – our aim is to help you process threats and put them in the past,
                allowing you to move on from anxiety and depression and get back to your life.
                We help people process threats and put them in the past, allowing you to move on from anxiety and depression and get back to your life.
              </p>
              <p>
                All experiences are first filtered by the brain’s threat system. Your response to a perceived threat is automatic.
                Complex thinking about a threat isn’t possible - you don’t want to be standing in the road determining the speed of the
                bus heading towards you, you just want to move.
              </p>
              <p>We respond to a threat in different ways:</p>
              <ul>
                <li>fight or flight (energy up, hyper-arousal), resulting in feelings of anxiety</li>
                <li>shut down and freeze (endergy down, hypo-arousal), resulting in feelings of depression</li>
              </ul>
              <p>
                Anxiety and depression are perfectly normal responses to environmental stimuli,
                but they can become crippling when they continue after the threat is gone -
                you can become overwhelmed and your brain gets stuck looping in the threat centre.
              </p>
              <p>
                {/* p is a paragraph; put something on a new line by adding a new paragraph */}

                We use therapies like <Link to="/therapies/emdr">EMDR</Link> and <Link to="/therapies/hypnotherapy">Hypnotherapy</Link> to help you
                process threats, and get your brain out of this damaging loop. This can help you with:</p>
              <ul>
                {/* ul is a list, li is a list item */}
                <li>disrupted thinking</li>
                <li>disconnect from normal functions, such as hunger and sex drive</li>
                <li>stress</li>
                <li>insomnia</li>
                <li>withdrawal from social situations</li>
                <li>dysfunctional coping mechanisms, such as alcohol and drug use, eating disorders, and self-harm</li>
              </ul>
              <p>More information is available at</p>
              <ul>
                <li><a target="_new" href="https://emdraa.org/">EMDR Association of Australia</a></li>
                <li><a target="_new" href="https://emdria.org/">EMDR International Association</a></li>
                <li><a target="_new" href="https://emdr.com/">EMDR Institute</a></li>
                <li><a target="_new" href="https://psychintegrity.org/">International Society for Ethical Psychology and Psychiatry</a></li>
              </ul>
            </div>
          </div>
        </div>
      </Section>

      <CallToAction />
      {/* <Resources></Resources> */}

    </Fragment>
  );
}
export default AnxietyAndDepression