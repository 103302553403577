import React from 'react';
import Section from '../../components/section/section'
import Memberships from '../../components/memberships/memberships';
import './memberships-section.scss';
function MembershipsSection(){
  // constructor(props) {
  //   props.className = "intro_section page_mainslider ds"
  //   super(props);
  // }
  return (
    <Section class="ls">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center membership-logos">
            <div className="membership-logo-wrapper"><img alt="Australian Association of Psychologists" src={Memberships.Aapi}/></div>
            <div className="membership-logo-wrapper"><img alt="Australian Pain Society" src={Memberships.AustralianPainSociety}/></div>
            <div className="membership-logo-wrapper"><img alt="Hypnotherapy Council of Australia" src={Memberships.HypnotherapyCouncilAustralia}/></div>
            <div className="membership-logo-wrapper"><img alt="International Society for Ethical Psychology and Psychiatry" src={Memberships.Isepp}/></div>
            <div className="membership-logo-wrapper"><img alt="Professional Clinical Hypnotherapists Of Australia" src={Memberships.ProfessionalClinicalHypnotherapistsOfAustralia}/></div>
          </div>
        </div>
      </div>
    </Section>
  );
}
export default MembershipsSection;