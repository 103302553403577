import React from 'react';
import { Fragment } from 'react';
import Section from '../../components/section/section'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import CallToAction from '../../components/call-to-action/call-to-action';

function Ocd() {
  return (
    <Fragment>
      <Helmet>
        <title>Manage OCD symptoms | Health Couch Psychology</title>
        <meta name="description" content="Process underlying causes of OCD, manage anxiety, reduce hypervigilance, and better manage symptoms"></meta>
      </Helmet>
      <Section class="ls section_padding_top_25 section_padding_bottom_60">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="section_header">OCD</h2>
              <p>Obsessive Compulsive Disorder</p>
            </div>
            <div className="col-sm-12">
              <p> The trauma model regards OCD as an attempt to manage anxiety from unprocessed trauma. </p>

              <p> We help people process the underlying causes of OCD, so they can better regulate their symptoms.
              We all experience feelings of uncertainty and anxiety from time to time, and we all go back and
                check things to make ourselves more comfortable. </p>

              <p> OCD happens when this checking is no longer optional - often feel compelled to check, and often
              afraid of their symptoms, resulting in increased anxiety which further fuels the OCD. This feedback
              loop of fear, anxiety, hypervigilance, and compulsive behaviour can be incredibly damaging to
                individuals and their families and frightening for those concerns. </p>

              <p> We use therapies like <Link to="/therapies/emdr">EMDR</Link> and
                <Link to="/therapies/hypnotherapy">Hypnotherapy</Link> and  <Link to="/therapies/psychotherapy">Parts Therapy</Link>
                to help people process the underlying causes, manage their anxiety,
                reduce their hypervigilance, and better manage their symptoms. </p>
            </div>
          </div>
        </div>
      </Section>

      <CallToAction />
      {/* <Resources></Resources> */}

    </Fragment>
  );
}
export default Ocd