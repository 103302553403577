import React from 'react';
import { Route, Routes } from "react-router-dom";

import Bookings from "./pages/bookings/bookings";
import Contact from "./pages/contact/contact";
import ContactThanks from "./pages/contact/contact-thanks";
import Covid19 from "./pages/covid-19/covid-19";
import Faq from "./pages/faq/faq";
import Home from "./pages/home/home";

import EmdrLanding from "./pages/emdr-landing";
import Emdr from "./pages/therapies/emdr";
import Hypnotherapy from "./pages/therapies/hypnotherapy";
import Psychotherapy from "./pages/therapies/psychotherapy";

import NewClient from "./pages/new-client";
import AnxietyAndDepression from "./pages/problems/anxiety-and-depression";
import ChronicPain from "./pages/problems/chronic-pain";
import FibromyalgiaAndChronicFatigue from "./pages/problems/fibromyalgia-and-chronic-fatigue";
import Ocd from "./pages/problems/ocd";
import Ptsd from "./pages/problems/ptsd";

import Consent from "./pages/consent/consent";
import News from "./pages/news";
import Practitioners from "./pages/practitioners/practitioners";
import PrivacyPolicy from "./pages/privacy-policy/privacy-policy";
import ChildAndAdolescent from "./pages/problems/child-and-adolescent";
import PanicAttacks from "./pages/problems/panic-attacts";
import ReducingMedication from "./pages/problems/reducing-medication";
import Resources from "./pages/resources/resources";
import SignaturePage from "./pages/signature";

const AppRoutes = () => (
<Routes>
  <Route path="/problems/anxiety-and-depression" element={<AnxietyAndDepression />}/>
  <Route path="/problems/chronic-pain" element={<ChronicPain />}/>
  <Route path="/problems/child-and-adolescent" element={<ChildAndAdolescent />}/>
  <Route path="/problems/fibromyalgia-and-chronic-fatigue" element={<FibromyalgiaAndChronicFatigue />}/>
  <Route path="/problems/safely-reducing-medication" element={<ReducingMedication />}/>
  <Route path="/problems/panic-attacks" element={<PanicAttacks />}/>
  <Route path="/problems/ptsd" element={<Ptsd />}/>          
  <Route path="/problems/ocd" element={<Ocd />}/>          
  <Route path="/emdr" element={<EmdrLanding />}/>          
  <Route path="/therapies/emdr" element={<Emdr />}/>          
  <Route path="/therapies/psychotherapy" element={<Psychotherapy />}/>          
  <Route path="/therapies/hypnotherapy" element={<Hypnotherapy />}/>
  <Route path="/contact/enquiry-received" element={<ContactThanks />}/>
  <Route path="/contact" element={<Contact/>} />
  <Route path="/news" element={<News />}/>
  <Route path="/covid-19" element={<Covid19 />}/>
  <Route path="/faq" element={<Faq />}/>
  <Route path="/bookings" element={<Bookings />}/>
  <Route path="/consent" element={<Consent />}/>
  <Route path="/signature" element={<SignaturePage />}/>
  <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
  <Route path="/practitioners" element={<Practitioners />}/>
  <Route path="/resources" element={<Resources />}/>
  <Route path="/new-client" element={<NewClient
      // personalDetails={true}
      // medicare={true}
      // healthInsurance={true}
    />} />
  <Route path="/free-initial-consult" element={<NewClient
      // personalDetails={false}
      // medicare={false}
      // healthInsurance={false}
    />} />
  <Route path="/" element={<Home />}/>
</Routes>
);

export default AppRoutes;