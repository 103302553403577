import React from 'react';
import { Fragment } from 'react';
import Section from '../../components/section/section'
import { Helmet } from 'react-helmet'
import CallToAction from '../../components/call-to-action/call-to-action';
import './style.scss';
import {IntroSection} from '../home';
import WelcomeSectionContent from '../home/welcome-section/welcome-section-content';

function Emdr() {
  return (
    <Fragment>
      <Helmet>
        <title>Evidence-based trauma therapy | Health Couch Psychology</title>
        <meta name="description" content=" Eye Movement Desensitisation and Reprocessing (EMDR) Therapy is a powerful 
          psychological treatment used primarily for treating trauma. EMDR has been used for over 25 years and shown to be effective
          in more than 20 controlled clinical studies."></meta>
      </Helmet>
      <IntroSection title={'EMDR'} subtitle={'Effective evidence based therapy'} subtitleMobile={'Eye Movement Desensitisation and Reprocessing'}/>
      
      <Section class="ls section_padding_top_25 section_padding_bottom_60">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 emdr-landing-page">
              <h3>Why EMDR?</h3>
              <p>
                Eye Movement Desensitisation and Reprocessing (EMDR) Therapy is a powerful psychological treatment
                used primarily for treating stress and trauma. It involves using bilateral stimuli such as a therapist’s
                moving fingers, tapping knees or lights moving across a screen, to help the brain process a traumatic
                experience and move on from the trauma reaction. EMDR has been used for over 25 years and shown to
                be effective in more than 20 controlled clinical studies.
              </p>
              <p>Health Couch use EMDR to help with:</p>
              <ul>
                <li>Chronic pain and phantom limb</li>
                <li>Anxiety, depression, and stress</li>
                <li>Fibromyalgia and chronic fatigue</li>
                <li>PTSD</li>
                <li>Child and adolescent issues </li>
                <li>Safely reducing medication</li>
                <li>Anxiety and panic attacks </li>
                <li>OCD</li>
                <li>Transgenerational issues</li>
              </ul>
              <p>More recently, we’ve also helped clients with COVID-19 concerns, and bushfire recovery.</p>
              <h3>Why Health Couch?</h3>
              <p>
                Principal Psychologist Susan Henderson has used EMDR for almost 20 years. 
                She’s a member of EMDRAA (EMDR Association Australia), and uses EMDR in tandem with other therapies like Clinical Hypnotherapy,
                Parts Therapy, Schema Therapy and Play Therapy, to help clients with a range of concerns. 
                Health Couch is currently offering telehealth appointments using Coviu (a secure encrypted telehealth platform) 
                and REMDR (remote EMDR platform) to assist clients during the coronavirus outbreak.
              </p>
              <WelcomeSectionContent/>
            </div>
          </div>
        </div>
      </Section>
      
      <CallToAction bookingUrl={"https://dolphinium-blues.au1.cliniko.com/bookings?practitioner_id=140274&appointment_type_id=466681"}/>
      
      {/* <Resources></Resources> */}

    </Fragment>
  );
}
export default Emdr