import React from 'react';
import {Fragment} from 'react';
import './home.scss';
// import Section from '../../components/section/section'
import IntroSection from './intro-section' 
import ProblemsSection from './problems-section'
import TherapiesSection from './therapies-section'
import WelcomeSection from './welcome-section'
import RatesSection from './rates-section'
import { Helmet } from 'react-helmet'
import CallToAction from '../../components/call-to-action/call-to-action'
import TelehealthBanner from '../../components/telehealth-banner/telehealth-banner'
import MembershipsSection from './memberships-section';

function Home() {
  return (
    <Fragment>
      <Helmet>
        <title>Telehealth psychology, hypnotherapy, and EMDR | Health Psychology</title>
        <meta name="description" content="Telehealth psychology, hypnotherapy and EMDR. We focus on treating underlying 
          issues rather than just managing symptoms. Helping you with OCD, chronic pain and more. Get in touch today."></meta>
      </Helmet>
      <IntroSection/>
      <TelehealthBanner/>
      <TherapiesSection/> 
      <WelcomeSection/>
      <ProblemsSection/> 
      <RatesSection/>
      <CallToAction/>
      <MembershipsSection/>
    </Fragment>
  );
}

export default Home;
