import React from "react";
import { Fragment } from "react";
import "./footer.css";
import Section from "../section/section";
import { Link } from "react-router-dom";

import Package from "../../../package.json";

function Footer() {
  return (
    <Fragment>
      <Section>
        <div className="text-center">
          <div className="disclaimer">
            We are not a crisis service. For crisis counselling contact Beyond Blue on 1300 22 4636, or Lifeline on 13 11 14, or 1800RESPECT (1800 737 732); for emergencies call 000.
          </div>
          <div className="small-text text-muted">
            &copy; 2023 Health Couch &nbsp;|&nbsp; All Rights Reserved
            &nbsp;|&nbsp;
            <Link to="/privacy-policy">Privacy Policy</Link>
            &nbsp;|&nbsp;
            <Link to="/consent">Consent</Link> &nbsp;|&nbsp;
            {Package.version}
          </div>
        </div>
      </Section>
    </Fragment>
  );
}

export default Footer;
