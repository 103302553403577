import React from 'react';
import {Fragment} from 'react';
import './consent.scss';
import Section from '../../components/section/section'
import { Helmet } from 'react-helmet'
import CancellationPolicy from './cancellation-policy';
const Consent = ({header=true}) => {
  return (
    <Fragment>
      {header ?
      <Helmet>
        <title>Health Couch Psychology | Consent Form</title>
        <meta name="description" content="Terms and conditions for provision of our services including telehealth consultations."></meta>
      </Helmet>
      : ''}
      <Section class="ls section_padding_top_30 section_padding_bottom_30">
        <div className="container">
          <div className="row-content clearfix">
            {header ? 
            <div className="col-sm-12 text-center">
              <h2 className="section_header">Consent Form</h2>
              <p>We take your privacy seriously</p>
            </div>
            : '' }
            <div className="col-sm-12 text-justify">
              <h3>Consent for a psychological telehealth service</h3>
              <p>
                As part of providing a psychological service to you, Health Couch needs to collect and record personal information from you that is relevant to
                your situation, such as your name, contact information, medical history and other relevant information as
                part of providing psychological services to you. This collection of personal information will be a necessary
                part of the psychological assessment and treatment that is conducted.
              </p>
              <p>
                A detailed description of how your personal information is managed, how you can access your personal
                information, and how to lodge any concerns or complaints about this service or how your personal
                information is managed must be provided to you by your health professional on request.
              </p>
              <h3>Disclosure of personal information</h3>
              <p>
                Personal information gathered as part of this service will remain confidential except when:
              </p>
              <ol>
                <li>it is subpoenaed by a court; or</li>
                <li>failure to disclose the information would place you or another person at serious risk to life, health or safety; or</li>
                <li>your prior approval has been obtained to
                  <ul>
                    <li>provide a written report to another professional or agency. e.g., a GP or a lawyer; or</li>
                    <li>discuss the material with another person, eg. a parent, employer or health provider; or</li>
                    <li>disclose the information in another way; or</li>
                  </ul>
                </li>
                <li>
                  you would reasonably expect your personal information to be disclosed to another professional or
                  agency (e.g. your GP) and disclosure of your personal information to that third party is for a
                  purpose which is directly related to the primary purpose for which your personal information was
                  collected; or
                </li>
                <li>disclosure is otherwise required or authorised by law.</li>
              </ol>
              <h3>Provision of a telehealth service</h3>
              <p>
                Where appropriate the service may be provided by telephone or videoconferencing. You are responsible
                for the costs associated with setting up the technology needed so you can access telehealth services.
                Health Couch will be responsible for the cost of the call to you and the cost
                associated with the platform used to conduct telehealth services.
              </p>
              <p>
              To access telehealth consultations you will need access to a quiet, private space; and the appropriate
              device, i.e. smartphone, laptop, iPad or computer, with a camera, microphone and speakers; and a reliable
              broadband internet connection.
              </p>
              <p>
                The privacy of any form of communication via the internet is potentially vulnerable and limited by the
                security of the technology used. To support the security of your personal information this practice uses
                Coviu which is compliant with the Australian standards for online security and encryption.
              </p>

              <h3>Limitations of telehealth</h3>
              <p>
                A telehealth consultation may be subject to limitations such as an unstable network connection which may
                affect the quality of the psychology session. In addition, there may be some services for which telehealth is
                not appropriate or effective. Your psychologist will consider and discuss with you the appropriateness of
                ongoing telehealth sessions.
              </p>

              <h3>Fees</h3>
              <p>
                The cost of a consultation is $230 for one hour, $280 for 90 minutes, or $360 for two hours. A deposit is payable 
                on booking, the residual is payable at the end of the session by credit card. Your psychologist will discuss 
                with you your eligibility for Medicare or other compensable funding.
              </p>

              <h3>Cancellation Policy</h3>
              <CancellationPolicy/>

              
              <h3>APS Charter for Clients of Psychologists</h3>
              <p>
                The <a href="https://www.psychology.org.au/for-the-public/about-psychology/why-choose-an-aps-psychologist/Charter-for-Clients-of-APS-Psychologists" target="_new">APS Charter for Clients of Psychologists</a> explains your rights as a client of a psychologist.
              </p>
            </div>
          </div>
        </div>
      </Section>
    </Fragment>
  )
};

export default Consent; 