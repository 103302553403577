import React from "react";
import { Fragment, useState } from "react";
import Section from "../../components/section/section";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import axios from "axios";
import MomentUtils from "@date-io/moment";
import "./intake-form.scss";

import Child from "./form-sections/child";
import WhySoughtHelp from "./form-sections/why-sought-help";
import Therapy from "./form-sections/therapy";
import HowFound from "./form-sections/how-found";
import Medicare from "./form-sections/medicare";
import Insurance from "./form-sections/insurance";
import Personal from "./form-sections/personal";

import ReadConsent from "./form-sections/read-consent";
import { ClientDetailsInterface } from "./types";
const moment = new MomentUtils();

const IntakeForm = ({
  client,
  onSubmit,
}: {
  client: ClientDetailsInterface;
  onSubmit: ({ sign_url }: { sign_url: string }) => null;
}) => {
  let initialValues = {};

  const previousAttempt = localStorage.getItem("new-client.details");
  if (previousAttempt) {
    initialValues = JSON.parse(previousAttempt);
  }

  const { register, handleSubmit, watch, errors, setValue } = useForm({
    defaultValues: initialValues,
  });

  const [progress, setProgress] = useState(false);
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState(false);

  const watchAll = watch();

  const handleChange = (e) => {
    localStorage.setItem("new-client.details", JSON.stringify(watchAll));
  };

  const submitResponse = (data) => {
    data["id"] = client?.id;
    setProgress(true);
    axios
      .post(
        "/api/initial-client",
        // "http://localhost:5000/api/initial-client",
        data
      )
      .then((res) => {
        setComplete(true);
        setProgress(false);
        onSubmit((res as any).data);
      })
      .catch((err) => {
        // console.error('Error encountered', err)
        setProgress(false);
        setError(true);
      });
  };

  if (error) {
    return (
      <div className="col-md-12 text-left">
        <div className="alert alert-warning">
          <p className="text-left">
            <strong>Uh oh</strong>
          </p>
          <p>
            Something went wrong at our end, you can try clicking "Submit"
            again, and if the problem persists, please{" "}
            <Link to="/contact">contact us</Link> for assistance.
          </p>
        </div>
      </div>
    );
  }

  if (complete) {
    return (
      <div className="col-md-12 text-left">
        <div className="alert alert-info">
          <p className="text-center">
            <strong>Thank you!</strong>
          </p>
          {client !== null &&
          client.next_appointment &&
          moment.date(client.next_appointment.starts_at) > moment.date() ? (
            <p className="text-center">
              We look forward to seeing you at your appointment on{" "}
              <strong>
                {moment
                  .date(client.next_appointment.starts_at)
                  .format("Do MMMM YYYY")}{" "}
                at{" "}
                {moment
                  .date(client.next_appointment.starts_at)
                  .format("h:mm a")}
              </strong>
              .
            </p>
          ) : (
            <p>
              We have received your information and look forward to speaking
              with you soon.
            </p>
          )}
        </div>
      </div>
    );
  }

  if (progress) {
    return (
      <p>
        <i className="fa fa-spinner fa-spin"></i> We're processing your response
      </p>
    );
  }

  return (
    <>
      <p className="text-center">Hi {client.first_name}</p>
      <p className="text-center">
        {client.next_appointment &&
        moment.date(client.next_appointment.starts_at) > moment.date() ? (
          <p className="text-center">
            We found you in our system; your next appointment is on{" "}
            <strong>
              {moment
                .date(client.next_appointment.starts_at)
                .format("Do MMMM YYYY")}{" "}
              at{" "}
              {moment.date(client.next_appointment.starts_at).format("h:mm a")}
            </strong>
            .
          </p>
        ) : (
          ""
        )}
        <p className="text-center">
          Thanks for confirming your details - please complete the following as
          best you can.
        </p>
        <p className="text-center">
          <em>
            (This helps us spend appointment time on what matters, rather than
            admin!)
          </em>
        </p>
      </p>
      <form
        className="new-client-form"
        onChange={(e) => handleChange(e)}
        onSubmit={handleSubmit(submitResponse)}
      >
        {/* Appointment for child */}
        <Child register={register} errors={errors} watch={watch} />

        {/* Why sought help */}
        <WhySoughtHelp register={register} errors={errors} watch={watch} />

        {/* Therapy */}
        <Therapy register={register} errors={errors} watch={watch} />

        {/* Source */}
        <HowFound register={register} errors={errors} watch={watch} />

        {/* Medicare */}
        <Medicare
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
        />

        {/* Insurance */}
        <Insurance
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
        />

        {/* Your Details */}
        <Personal
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
        />

        <div className="col-md-12 text-right">
          <button type="submit">Submit</button>
        </div>
      </form>
    </>
  );
};

const IntakeFormWrapper = (props) => (
  <Section class="ls section_padding_top_25 section_padding_bottom_60">
    <div className="container">
      <div className="row">
        <IntakeForm {...props} />
      </div>
    </div>
  </Section>
);

export default IntakeFormWrapper;
