import React from 'react';
import { Fragment } from 'react';
import Section from '../../components/section/section'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import CallToAction from '../../components/call-to-action/call-to-action';

function FibromyalgiaAndChronicFatigue() {
  return (
    <Fragment>
      <Helmet>
        <title>Regulate pain and fatigue | Health Couch Psychology</title>
        <meta name="description" content="We empower you to better control your symptoms. 
          Help your brain remember and reactivate how you felt before the pain. Learn to 
          identify symptoms and turn them down so they don’t become crippling"></meta>
      </Helmet>
      <Section class="ls section_padding_top_25 section_padding_bottom_60">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="section_header">Fibromyalgia and chronic fatigue</h2>
              {/* <p>Anxiety, Depression and Stress</p> */}
            </div>
            <div className="col-sm-12">
              <p>
                We help people regulate pain and fatigue and feel more in control. Fibromyalgia and
                chronic fatigue significantly impact quality of life and often result in feelings of
                hopelessness and helplessness. </p>

              <p>We use therapies like <Link to="/therapies/emdr">EMDR</Link> and <Link to="/therapies/emdr">Hypnotherapy</Link> to empower you to better control your symptoms: </p>
              <ul>
                <li>help your brain remember and reactivate how you felt before the pain</li>
                <li>help you identify symptoms and turn them down so they don’t become crippling</li>
              </ul>

            </div>
          </div>
        </div>
      </Section>

      <CallToAction />
      {/* <Resources></Resources> */}

    </Fragment>
  );
}
export default FibromyalgiaAndChronicFatigue