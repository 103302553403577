import React from 'react';
// import {Fragment} from 'react';
import Section from '../../components/section/section'
import {
  Link
} from "react-router-dom";

function TherapiesSection(){
  // constructor(props) {
  //   props.className = "intro_section page_mainslider ds"
  //   super(props);
  // }
  return (
    <Section class="ls section_padding_top_25 section_padding_bottom_60">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h2 className="section_header">Therapies we offer</h2>
            {/* <p>
              Porchetta dolor magna ipsum. Irure beef ribs chicken dolore, laboris filet mignon ribeye bacon swine pork loin commodo pork chop corned beef hamburger.
            </p> */}
          </div>
        </div>
        <div className="row columns_padding_0 columns_margin_0 fontsize_16 therapies">
          <div className="col-md-4 col-sm-6 therapy">
            <Link to="/therapies/emdr">
              <div className="with_padding text-center teaser hover_shadow">
                <img alt="EMDR" src="static/vendor/images/service-icons/relationship.png"  />
                <h4>
                  EMDR
                </h4>
                <p>
                  Evidence-based trauma therapy
                </p>
              </div>
            </Link>
          </div>
          <div className="col-md-4 col-sm-6 therapy">
            <Link to="/therapies/hypnotherapy">
              <div className="with_padding text-center teaser hover_shadow">
                <img alt="Hypnotherapy" src="static/vendor/images/service-icons/meditation.png"  />
                <h4>
                  Clinical Hypnotherapy
                </h4>
                <p>
                  Accessing the sub-conscious to improve therapy outcomes
                </p>
              </div>
            </Link>
          </div>
          <div className="col-md-4 col-sm-6 therapy clear-sm">
            <Link to="/therapies/psychotherapy">
              <div className="with_padding text-center teaser hover_shadow">
                <img alt="Psychotherapy" src="static/vendor/images/service-icons/relaxation.png"  />
                <h4>
                  Psychotherapy
                </h4>
                <p>
                  Psychological treatments tailored to your needs
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </Section>
  );
}
export default TherapiesSection;