import React from 'react';

const SignaturePage = () => {
  return (
    <>
      <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
      <script src="https://cdncf.esignatures.io/staticassets/iframeResizer.4.2.10.min.js"></script>
      <iframe
          title="Please Sign"
          src="https://esignatures.io/signle/498a12fc-a0fb-4116-96fb-61e34acc87d3"
          id="eSignaturesIOIframe"
          // onload={iFrameResize({heightCalculationMethod: 'bodyScroll'}, '#eSignaturesIOIframe')}
          style={{width: "1px", minWidth: "100%"}}>
      </iframe>
    </>
  )
}

export default SignaturePage;