import React, { Fragment } from 'react';
// import {Fragment} from 'react';
import './welcome-section.scss'
function WelcomeSectionContent(){
  return (
    <Fragment>
      <p>
        Health Couch follows a trauma model and uses trauma-focussed therapies. Nobody does something for no reason; we are all 
        products of our experience and our automatic brain responses.      
        Therapy is about managing and processing experiences, regulating emotional and physical pain, and changing neural pathways, 
        so that people no longer become overwhelmed.
      </p>
      <p>
        Every brain is subtly different because we all have different internal and external experiences. Therapy is 
        tailored to your personality; your philosophy and beliefs; your past experiences; what you feel comfortable with; 
        and your reasons for seeking assistance.
      </p>
    </Fragment>
  )
}
export default WelcomeSectionContent;