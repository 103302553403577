import React, { Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import "./style.scss";
import Section from "../../components/section/section";

import Article from "./article";
import articles from "./news-articles.json";

const colors = [
  { backgroundColor: null, color: "inherit" },
  { backgroundColor: "#f4f4f7", color: "inherit" },
];

const NewsIndex = () => {
  const pageSize = 2;
  const [limit, setLimit] = useState(pageSize);
  const totalArticles = articles.length;

  return (
    <Fragment>
      <Helmet>
        <title>News</title>
        <meta name="description" content="Health Couch news."></meta>
      </Helmet>

      <div className="col-sm-12" style={{ flexGrow: 0 }}>
        <h2 className="section_header text-center">News</h2>
      </div>

      {articles
        .filter((x, idx) => idx < limit)
        .map((x, idx) => (
          <Section
            key={idx}
            style={{
              backgroundColor: colors[idx % 2].backgroundColor as string,
              color: colors[idx % 2].color as string,
            }}
          >
            <div className="container">
              <div className="clearfix">
                <Article {...x} />
              </div>
            </div>
          </Section>
        ))}

      {limit < totalArticles ? (
        <div className="col-sm-12 text-center">
          <button
            onClick={() => setLimit((existing) => (existing += pageSize))}
          >
            Load More
          </button>
        </div>
      ) : null}
    </Fragment>
  );
};

export default NewsIndex;
