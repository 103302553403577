  
import React, {Fragment} from 'react';
const Personal = ({register, errors, watch, setValue}) => {
  
  return ( 
    <Fragment>
    <div className="col-sm-12">
      <h2>About You</h2>
      <div className="form-group">
        <label htmlFor="details_preferred_name">Preferred name</label>
        <input type="text" id="details_preferred_name" name="details_preferred_name" autoComplete={"details_preferred_name"} ref={register({ required: false })}/>
      </div>
      <div className="form-group">
        <label htmlFor="details_address">Address</label>
        <input type="text" id="details_address" name="details_address" autoComplete={"details_address"} ref={register({ required: true })}/>
        <div className="error">{errors.details_address ? 'Please complete this field' : ''}</div>
      </div>
      <div className="form-group">
        <label htmlFor="details_city">Town</label>
        <input type="text" id="details_city" name="details_city" autoComplete={"details_city"} ref={register({ required: true })}/>
        <div className="error">{errors.details_city ? 'Please complete this field' : ''}</div>
      </div>
      <div className="form-group">
        <label htmlFor="details_postcode">Postcode</label>
        <input type="text" id="details_postcode" name="details_postcode" autoComplete={"details_postcode"} ref={register({ required: true })}/>
        <div className="error">{errors.details_postcode ? 'Please complete this field' : ''}</div>
      </div>
      <div className="form-group">
        <label htmlFor="details_occupation">Occupation</label>
        <input type="text" id="details_occupation" name="details_occupation" autoComplete={"details_occupation"} ref={register({ required: true })}/>
        <div className="error">{errors.details_occupation ? 'Please complete this field' : ''}</div>
      </div>
      <div className="form-group">
        <label htmlFor="details_doctor">Doctor (and clinic)</label>
        <input type="text" id="details_doctor" name="details_doctor" autoComplete={"details_doctor"} ref={register({ required: false })}/>
      </div>
    </div>
    <div className="col-sm-12">
      <h2>Emergency contact</h2>
      <p className="consent">
        I have been provided with information about the service including the limitations to privacy and confidentiality 
        and I have agreed that in circumstances where the psychologist is concerned about my welfare and is unable to 
        contact me permission is provided for the psychologist to contact the following person:
      </p>
      <div className="form-group">
        <label htmlFor="details_emergency_contact">Emergency contact name, details and relationship to you</label>
        <input type="text" id="details_emergency_contact" name="details_emergency_contact" autoComplete={"details_emergency_contact"} ref={register({ required: true })}/>
        <div className="error">{errors.details_emergency_contact ? 'Please complete this field' : ''}</div>
      </div>
    </div>
    </Fragment>
  );
};
export default Personal;