import React from 'react';
import './resources.scss'

const Resource = ({title, text, link, img, resourceType}) => {
  return (
    <a href={link} target="_new">
      <div className="resource with_shadow clearfix row">
        {/* { resourceType == 'video' ? <i className='fa fa-play-circle'></i> : ''}
        { resourceType == 'website' ? <i className='fa fa-globe'></i> : '' }
        { resourceType == 'book' ? <i className='fa fa-book'></i> : '' } */}
        {img ? 
        <div className="image-wrapper hidden-xs">
          <img src={img} alt={title}/>
        </div>
        : '' }
        <div className="text-wrapper">
          <div>
            <h3>{title}</h3>
            <div className="image-wrapper hidden-md hidden-lg hidden-xl">
              <img src={img} alt={title}/>
            </div>
            <p>{text}</p>
          </div>
        </div>
      </div>
    </a>
  );
}
export default Resource