import React from 'react';
import {useState} from 'react';
import Section from '../../components/section/section'
import axios from 'axios';
import {Link} from 'react-router-dom';

import { useForm } from "react-hook-form";


const ContactForm = (props) => {

  const [progress, setProgress] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [agreePrivacy, setAgreePrivacy] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [notes, setNotes] = useState("");
  const [therapy, setTherapy] = useState("");
  const [problem, setProblem] = useState("");

  const [showTherapy, setShowTherapy] = useState(false);
  const [showProblem, setShowProblem] = useState(false);

  const [howFound, setHowFound] = useState("");

  const { handleSubmit, register, errors } = useForm();
  const onSubmit = values => {
    // console.log(values);
    // evt.preventDefault();
    // evt.stopPropagation();
    // alert(`Submitting Name=${name}, Email=${email}, phone=${phone}, message=${message}`);
    setSent(false);
    setProgress(true);
    setError(false);
    
    const payload = { 
      name, 
      email, 
      phone, 
      notes, 
      therapy: therapy ? therapy : null, 
      how_found: (howFound ? howFound : null), 
      problem: problem ? problem : null
    }

    axios.post(
      '/api/message',
      // 'http://localhost:5000/message',
      payload
    ).then(res => {
      setSent(true);
      setProgress(false);
      props.history.push("/contact/enquiry-received");
    }).catch(err => {
      setProgress(false);
      setError(true);
      setSent(false);
    })
  };


  // const handleSubmit = (evt) => {
    
  // }

  const handleShowProblem = () => {
    setShowProblem(!showProblem);
  }

  const handleShowTherapy = () => {
    setShowTherapy(!showTherapy);
  }

  return (
    <Section className="ls columns_padding_25 section_padding_top_100 section_padding_bottom_100">
      <div className="container contact-form">
        <div className="row">
          <div className="col-md-12">
            <form className="contact-form row columns_margin_bottom_40" onSubmit={handleSubmit(onSubmit)}>

              <div className="col-sm-12">
                <div className="contact-form-name">
                  <label htmlFor="name">Your Name
                    <span className="required">*</span>
                  </label>
                  <input 
                    type="text" 
                    aria-required="true" 
                    size="30" 
                    name="name" 
                    className="form-control" 
                    placeholder="Your Name" 
                    onChange={e => setName(e.target.value)}
                    ref={register({
                      // eslint-disable-next-line
                      validate: value => value && value.length >= 2 || "Please enter your name"
                    })}
                  />
                  <div className="error">{errors.name && errors.name.message}</div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="contact-form-email">
                  <label htmlFor="email">Email address
                    <span className="required">*</span>
                  </label>
                  <input 
                    type="text" 
                    aria-required="true" 
                    size="30" 
                    // value={email} 
                    name="email" 
                    className="form-control" 
                    placeholder="Email Address" 
                    onChange={e => setEmail(e.target.value)}
                    ref={register({
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Please enter a valid email address"
                      },
                      // eslint-disable-next-line
                      validate: value => value && value.length > 0 || "Please enter an email address"
                    })}
                  />
                  <div className="error">{errors.email && errors.email.message}</div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="contact-form-phone">
                  <label htmlFor="phone">Phone
                    <span className="required">*</span>
                  </label>
                  <input 
                    type="text" 
                    aria-required="true" 
                    size="30" 
                    name="phone" 
                    className="form-control" 
                    placeholder="Phone" 
                    onChange={e => setPhone(e.target.value)}
                    ref={register({
                      // eslint-disable-next-line
                      validate: value => value && value.length > 8 || "Please enter a valid phone number"
                    })}
                  />
                  <div className="error">{errors.phone && errors.phone.message}</div>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="contact-form-referral">
                  <label>Where did you hear about us?</label>
                  <select value={howFound} onChange={e => setHowFound(e.target.value)}>
                    <option value="">Please select an option</option>
                    <option value="friend-or-family">Friend or family member</option>
                    <option value="gp-referral">GP referral</option>
                    <option value="google">Google / web search</option>
                    <option value="google">Third party website</option>
                    <option value="other">Other</option>
                    </select>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="contact-form-therapy">
                  <label>
                    <input type="checkbox" value={showTherapy} onChange={handleShowTherapy}/>
                    I am interested in a specific therapy (optional)
                  </label>
                  <select value={therapy} onChange={e => setTherapy(e.target.value)}  className={!showTherapy ? 'is-hidden' : ''}>
                    <option value="">Please select an option</option>
                    <option value="emdr">EDMR</option>
                    <option value="hypnotherapy">Clinical Hypnotherapy</option>
                    <option value="other">Other</option>
                    </select>
                </div>

                <div className="contact-form-problem">
                  <label>
                    <input type="checkbox" value={showProblem} onChange={handleShowProblem}/>
                    I need help with a specific problem (optional)
                  </label>
                  <select value={problem} onChange={e => setProblem(e.target.value)} className={!showProblem ? 'is-hidden' : ''}>
                    <option value="">Please select an option</option>
                    <option value="chronic-pain">Chronic pain or phantom limb</option>
                    <option value="anxiety-and-depression">Anxiety, depression or stress</option>
                    <option value="fibromyalgia-and-chronic-fatigue">Fibromyalgia or chronic fatigue</option>
                    <option value="ptsd">PTSD</option>
                    <option value="child-and-adolescent">Child and adolescent therapy</option>
                    <option value="safely-reducing-medication">Safely reducing medication</option>
                    <option value="panic-attacks">Anxiety or panic attacks</option>
                    <option value="panic-attacks">OCD</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="contact-form-message">
                  <label htmlFor="message">Message</label>
                  <textarea aria-required="true" rows="1" cols="45" name='notes' className="form-control" placeholder="Notes" onChange={e => setNotes(e.target.value)} value={notes}></textarea>
                </div>
              </div>

              <div className="col-sm-12 text-left contact-form-privacy">
                <label>
                  <input 
                    type="checkbox" 
                    name="privacy"
                    checked={agreePrivacy} 
                    onChange={e => setAgreePrivacy(e.target.checked)}
                    ref={register({
                      validate: value => value || "You must agree to our privacy policy to submit this enquiry"
                    })}
                  />
                  I have read and agree to the Health Couch <Link to="/privacy-policy">privacy policy</Link>.
                  <div className="error">{errors.privacy && errors.privacy.message}</div>
                </label>
              </div>

              <div className="col-sm-12">
                { !progress && !sent ? (
                <div className="contact-form-submit topmargin_20">
                  <button type="submit" id="contact_form_submit" name="contact_submit" className="theme_button color1 with_shadow">Send Message</button>
                </div>
                ) : ''}

                { progress ? (
                  <p><i className='fa fa-spinner fa-spin'></i> Submitting your enquiry</p>
                ) : ''}

                { error ? (
                  <p><i className='fa fa-exclamation-triangle'></i> Failed to submit enquiry, please try again.</p>
                ) : ''}

                { sent ? (
                  <p><i className='fa fa-check'></i> Enquiry submitted, thank you.</p>
                ) : ''}
              </div>

            </form>
          </div>
        </div>

      </div>

    </Section>
  );
}

export default ContactForm;