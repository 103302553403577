import React from 'react';
// import {Fragment} from 'react';
import Section from '../../components/section/section'
import Problem from '../../components/problem/problem'
import icons from '../../components/icons/icons'
import './problems-section.scss';
function ProblemsSection(){
  return (
    <Section class="ls section_padding_top_30 section_padding_bottom_60">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h2 className="section_header">What we help with</h2>
          </div>
        </div>
        <div className="row columns_padding_0 columns_margin_0 fontsize_16 problems">
          <Problem 
            title="Chronic pain and phantom limb" 
            link="/problems/chronic-pain"
            icon="static/vendor/images/service-icons/conflicting.png"
            subtitle="Managing pain perception"
          />
          <Problem 
            title="Anxiety, Depression and stress" 
            link="/problems/anxiety-and-depression"
            icon="static/vendor/images/service-icons/depression.png"
            subtitle="Processing threats and regulating your emotions"
          />
          <Problem 
            title="Fibromyalgia and chronic fatigue" 
            link="/problems/fibromyalgia-and-chronic-fatigue"
            icon="static/vendor/images/service-icons/mind.png"
            subtitle="Regulating your symptoms"
          />
          <Problem 
            title="PTSD" 
            link="/problems/ptsd"
            icon="static/vendor/images/service-icons/mental.png"
            subtitle="Processing trauma"
          />
          <Problem 
            title="Child and Adolescent therapy" 
            link="/problems/child-and-adolescent"
            icon={icons.ChildAndAdolescent}
            subtitle="Helping the little people in your life"
          />
          <Problem 
            title="Safely Reducing Medication" 
            link="/problems/safely-reducing-medication"
            icon={icons.SafelyReducingMedication}
            subtitle="In consultation with your GP"
          />
          <Problem 
            title="Anxiety and Panic Attacks" 
            link="/problems/panic-attacks"
            icon={icons.AnxietyAndPanicAttacks}
            subtitle="Teaching your brain to regulate them"
          />
          <Problem 
            title="OCD" 
            link="/problems/ocd"
            icon={icons.Ocd}
            subtitle="Identifying anxiety and managing symptoms"
          />
        </div>
      </div>
    </Section>
  );
}
export default ProblemsSection;