import React from 'react';
import Section from '../../components/section/section'
import './rates-section.scss'


const RatesSection = () => {
  return (
    <Section class="cs rates-section">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h2 className="section_header">Rates</h2>
          </div>
        </div>
        <div className="row flex-row price-row">
          <div className="col-md-3 col-sm-12">
            <div className="price-table">
              <div className="price-media">
                <div className="plan-name">
                  1 hour
                </div>
                <div className="plan-price">
                  $230
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="price-table">
              <div className="price-media">
                <div className="plan-name">
                  90 mins
                </div>
                <div className="plan-price">
                  $280
                  <p className="small-text">(recommended)</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="price-table">
              <div className="price-media">
                <div className="plan-name">
                  2 hours
                </div>
                <div className="plan-price">
                  $360
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}
export default RatesSection;