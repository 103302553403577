import React from "react";
import "./section.scss";

const Section = (props) => {
  const { color, backgroundColor } = props.style || {};
  // console.log(color, backgroundColor);
  return (
    <section
      className={props.class ? props.class : ""}
      style={{ color, backgroundColor }}
    >
      {props.children}
    </section>
  );
};

export default Section;
