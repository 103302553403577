import React from 'react';
import { Fragment } from 'react';
import Section from '../../components/section/section'
import { Helmet } from 'react-helmet'
import CallToAction from '../../components/call-to-action/call-to-action';

function Psychotherapy() {
  return (
    <Fragment>
      <Helmet>
        <title>Schema therapy, parts therapy and more | Health Couch Psychology</title>
        <meta name="description" content="A range of psychological treatments tailored to your individual needs."></meta>
      </Helmet>
      <Section class="ls section_padding_top_25 section_padding_bottom_60">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="section_header">Psychotherapy</h2>
              {/* <p>Anxiety, Depression and Stress</p> */}
            </div>
            <div className="col-sm-12">
              <p>Psychotherapy is an umbrella term for a range of psychological treatments tailored to your individual needs. </p>
              <p>It may include:</p>
              <ul>
                <li>Art Therapy </li>
                <li>Play Therapy </li>
                <li>Cognitive Behavioural Therapy (CBT) </li>
                <li>Parts Therapy</li>
                <li>Psycho-education</li>
                <li>Schema Therapy</li>
                <li>Ego-State Therapy</li>
              </ul>
            </div>
          </div>
        </div>
      </Section>

      <CallToAction />
      {/* <Resources></Resources> */}

    </Fragment>
  );
}
export default Psychotherapy