import React, { Fragment } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const ScrollToTop: React.FunctionComponent = ({children}: any) => {
  const history = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history]);
  return <Fragment>{children}</Fragment>;
}

export default ScrollToTop;