import AnxietyAndPanicAttacks from './anxiety-and-panic-attacks.png';
import ChildAndAdolescent from './child-and-adolescent.png';
import Ocd from './ocd.png';
import SafelyReducingMedication from './safely-reducing-medication.png';
const Icons = {
  AnxietyAndPanicAttacks,
  ChildAndAdolescent,
  Ocd,
  SafelyReducingMedication
}
export default Icons;