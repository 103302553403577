import React from "react";
import "./bookings.scss";
import Section from "../../components/section/section";
import { Helmet } from "react-helmet";

window.addEventListener("message", function handleIFrameMessage(e) {
  var clinikoBookings = document.getElementById("cliniko-76433790");
  if (typeof e.data !== "string") return;
  if (e.data.search("cliniko-bookings-resize") > -1) {
    var height = Number(e.data.split(":")[1]);
    clinikoBookings.style.height = height + "px";
  }
  e.data.search("cliniko-bookings-page") > -1 &&
    clinikoBookings.scrollIntoView();
});

function Book() {
  return (
    <Section class="ls booking">
      <Helmet>
        <title>
          Get help today - free initial 15 minute | Health Couch Psychology
        </title>
        <meta
          name="description"
          content="Health Couch is currently accepting online bookings for telehealth sessions. Treating issues
          as well as managing symptoms. Get help today. Free 15 minute initial chat - see if we're right for you."
        ></meta>
      </Helmet>
      <div className="col-sm-12">
        <div className="alert alert-warning text-left covid-disclaimer-bookings">
          
          <p>
            We are only offering phone and video consultations at this time.
          </p>
          <p>
            A deposit is payable at the time of booking; the below prices are
            deposit only. The final invoice will be provided after the session
            and reflect the deposit paid. Deposits are refundable for
            cancellations with more than 24 hours notice, forfeit with less than
            24 hours notice.
          </p>
        </div>
      </div>
      <div className="col-sm-12 text-center">
        <h2 className="section_header">Bookings</h2>
        <iframe
          title="booking-iframe"
          id="cliniko-76433790"
          src="https://dolphinium-blues.au1.cliniko.com/bookings?business_id=89828&practitioner_id=140274&embedded=true"
          frameborder="0"
          scrolling="auto"
          width="100%"
          height="600px"
          style={{ pointerEvents: "auto", height: 600 }}
        />
      </div>
    </Section>
  );
}

export default Book;
