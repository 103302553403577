import React from "react";
// import {Link} from 'react-router-dom';

function ContactDetails() {
  return (
    <div className="col-md-12 row">
      <div className="small-teaser media fontsize_16 col-sm-12">
        <div className="media-left">
          <i className="highlight fa fa-phone"></i>
        </div>
        <div className="media-body">0416 057 570</div>
      </div>

      <div className="small-teaser media fontsize_16 col-sm-12">
        <div className="media-left">
          <i className="highlight fa fa-envelope-o"></i>
        </div>
        <div className="media-body">
          <a href="mailto:help@health-couch.com.au">help@health-couch.com.au</a>
        </div>
      </div>

      <div className="small-teaser media fontsize_16 col-sm-12">
        <div className="media-left">
          <i className="highlight fa fa-map-marker"></i>
        </div>
        <div className="media-body">77 Anne Road, Knoxfield VIC 3180</div>
      </div>
    </div>
  );
}

export default ContactDetails;
