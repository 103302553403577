import React from 'react';
import { Fragment } from 'react';
import Section from '../../components/section/section'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import CallToAction from '../../components/call-to-action/call-to-action';

function PanicAttacks() {
  return (
    <Fragment>
      <Helmet>
        <title>Priocess the threat and regulate the attacks | Health Couch Psychology</title>
        <meta name="description" content="Anxiety attacks and panic attacks are physical manifestations of threat response. We help you
          process threats and regulate the attacks"></meta>
      </Helmet>
      <Section class="ls section_padding_top_25 section_padding_bottom_60">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="section_header">Anxiety attacks and panic attacks</h2>
              {/* <p>Anxiety, Depression and Stress</p> */}
            </div>
            <div class="col-sm-12">
              <p> Anxiety attacks and panic attacks are physical manifestations of threat.
                  When your brain identifies a threat you might feel your heart skip a beat or find it hard to breathe. </p>
              <p>
                Trauma therapies regard these attacks as your experience of hypo- or hyper-arousal.
                We use therapies like <Link to="/therapies/emdr">EMDR</Link> and <Link to="/therapies/emdr">Hypnotherapy</Link>
                to process the threat and regulate the attacks.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <CallToAction />
      {/* <Resources></Resources> */}

    </Fragment>
  );
}
export default PanicAttacks