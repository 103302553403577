// import imageNotFound from './images/luci.jpg';
import resources from './json/resources.json';
export const resourceList = resources.map( section => {
  section.items = section.items.map(item => {
    try {
      item.image = require(`./images/${item.file}`);
     } catch (err) {
      item.image = null;
     }
    
    return item;
  });
  return section;
});