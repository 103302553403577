import React from 'react';
import { Fragment } from 'react';
import Section from '../../components/section/section'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import CallToAction from '../../components/call-to-action/call-to-action';

function Ptsd() {
  return (
    <Fragment>
      <Helmet>
        <title>Process trauma, stop being triggered, manage symptoms | Health Couch Psychology</title>
        <meta name="description" content="We help people process the event, so that they can stop reliving the trauma and get on with their lives."></meta>
      </Helmet>
      <Section class="ls section_padding_top_25 section_padding_bottom_60">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="section_header">PTSD</h2>
              <p>Post Traumatic Stress Disorder</p>
            </div>
            <div className="col-sm-12">
              <p>We use trauma therapies to help people process traumatic events, so that they can stop reliving
              the trauma and get on with their lives. Post traumatic stress disorder (PTSD) is the name given
              to the most severe form of stress reaction. We all experience trauma at some time and to varying
              degrees. Trauma therapies define trauma as the reaction rather than the event itself, and the
                emphasis is on effectively dealing with that.  </p>

              <p>As per anxiety and depression, when you experience severe trauma, you can become overwhelmed and
              your brain gets stuck on the threat. People with PTSD may have intense trauma-related thoughts and
              feelings, relive the event through flashbacks or nightmares, and have strong reactions to otherwise
                ordinary stimuli (e.g. loud noises) which trigger the trauma. </p>

              <p>People experiencing PTSD may also have strong trauma-related emotions such as anger, guilt,
              shame and irritability which affect how they interact with others, and result in relationship
              and parenting conflicts. We use therapies like EMDR and Hypnotherapy to help people process the event,
                  so that they can stop reliving the trauma and get on with their lives. </p>

              <p>We use therapies like <Link to="/therapies/emdr">EMDR</Link> and <Link to="/therapies/hypnotherapy">Hypnotherapy</Link> to help people process the event, so that they can stop reliving the trauma and get on with their lives. </p>

              <p>The use of EMDR is in line with Phoenix Australia (Centre for Posttraumatic Mental Health)
                recommendations for treating adults with PTSD. </p>

            </div>
          </div>
        </div>
      </Section>

      <CallToAction />
      {/* <Resources></Resources> */}

    </Fragment>
  );
}
export default Ptsd