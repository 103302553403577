  
import React from 'react';

const Insurance = ({register, errors, watch, setValue}) => {
  const insurance_do_you_have = watch("insurance_do_you_have");
  return ( 
    <div className="col-sm-12">
      <h2>Health Insurance</h2>
      <div className="form-group">
        <label htmlFor="insurance_do_you_have">Do you have private health insurance?</label>
        <select className="form-control" name="insurance_do_you_have" id="insurance_do_you_have" ref={register({ required: true })}>
          <option value="">- Please select</option>
          <option>Yes</option>
          <option>No</option>
        </select>
        <div className="error">{errors.insurance_do_you_have ? 'Please select yes/no' : ''}</div>
      </div>
      { insurance_do_you_have === "Yes" ? (
      <div className="form-group">
        <label htmlFor="insurance_insurer_name">What is the name of your private health insurance provider?</label>
        <input type="text" id="insurance_insurer_name" name="insurance_insurer_name" autoComplete={"insurance_insurer_name"} ref={register({ required: insurance_do_you_have === "Yes" })}/>
        <div className="error">{errors.insurance_insurer_name ? 'Please complete this field' : ''}</div>
      </div>
      ) : ''}
      { insurance_do_you_have === "Yes" ? (
      <div className="form-group">
        <label htmlFor="insurance_insurer_customer_id">What is your private health member number, and your reference number?</label>
        <input type="text" id="insurance_insurer_customer_id" name="insurance_insurer_customer_id" autoComplete={"insurance_insurer_customer_id"} ref={register({ required: insurance_do_you_have === "Yes" })}/>
        <div className="error">{errors.insurance_insurer_customer_id ? 'Please complete this field as best you can' : ''}</div>
      </div>
      ) : '' }
    </div>
  );
}

export default Insurance;