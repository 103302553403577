import React from 'react';
// import {Fragment} from 'react';
import Section from '../../../components/section/section'
import './welcome-section.scss' 
import susan from "../../../susan-v2.png";
import WelcomeSectionContent from './welcome-section-content'
 
function WelcomeSection(){
  return (
    <Section class="cs parallax darken_gradient page_about ">
      <div class="container">
        <div class="welcome-section">
          <div className="col-image">
            <img src={susan} alt={"Welcome to health couch"} className="welcome-image"/>
          </div>
          <div className="col-text text-wrapper about-us">
            <h2 className="text-center">About us.</h2>
            <p className="bold fontsize_18 blurb"> We focus on treating underlying issues rather than just managing symptoms.</p>
            <WelcomeSectionContent/>
            <div className="topmargin_30 bottommargin_30">
              <h5 className="small-text text-uppercase inline-block">Susan Henderson</h5><br/>
              <span className="lightgrey">Principal Psychologist</span>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}
export default WelcomeSection;