import React from "react";
import moment from "moment";
export interface ArticleInterace {
  title: string;
  body: string[];
  link?: string;
  image?: string;
  date: string;
}

const Article = ({ title, body, link, image, date }: ArticleInterace) => {
  let imageFile = null;
  try {
    imageFile = require(`./images/${image}`);
  } catch {}

  return (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        marginBottom: 100,
        marginTop: 20,
      }}
    >
      <div style={{ textAlign: "center" }}>
        {imageFile ? (
          <img
            src={imageFile}
            alt={title}
            style={{
              maxWidth: 300,
              maxHeight: 300,
              marginTop: 20,
              marginBottom: 20,
            }}
          />
        ) : null}
      </div>
      <h3 style={{ marginBottom: 8 }}>{title}</h3>
      <p style={{ opacity: 0.8, marginTop: 0 }}>
        Susan Henderson, {moment(date).format("ll")}
      </p>
      {body.map((x, idx) => (
        <p key={idx}>{x}</p>
      ))}

      {link ? <a href={link}>Read More</a> : null}
    </div>
  );
};

export default Article;
