import React from "react";
import "./contact.scss";
import Section from "../../components/section/section";
import { Helmet } from "react-helmet";

import ContactForm from "./contact-form";
import ContactDetails from "./contact-details";
import { Link } from "react-router-dom";

const Contact = (props) => {
  return (
    <div>
      <Helmet>
        <title>
          Contact - telehealth Australia-wide | Health Couch Psychology
        </title>
        <meta
          name="description"
          content="Contact us today. Based in Knoxfield, Melbourne suburbs; telehealth Australia-wide
          and world-wide during the novel coronavirus (COVIS-19) outbreak. Get help today."
        ></meta>
      </Helmet>
      <Section class="ls section_padding_bottom_40">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="alert alert-warning text-left covid-disclaimer">
                
                <p>
                  We are only offering phone and video consultations at this
                  time.
                  <Link to="/bookings"> Book a teleahealth session today</Link>,
                  or get in touch below.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="col-sm-12 text-center contact-details">
                <h2 className="section_header">Contact</h2>
                <ContactDetails />
              </div>
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="col-sm-12 text-center enquiries">
                <h2 className="section_header">Enquiries</h2>
                <ContactForm history={props.history} />
              </div>
            </div>
          </div>
        </div>
      </Section>

      {/* <Section class="ls section_padding_top_130 section_padding_bottom_100">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="section_header with_icon">New Patient Details</h2>
              <p>
                If you would like to save time on the day...
              </p>
            </div>
          </div>
        </div>
      </Section> */}
    </div>
  );
};

export default Contact;
