import Aapi from './images/aapi.png'
import AustralianPainSociety from './images/australian-pain-society.jpg';
import Emdraa from './images/emdraa.png';
import HypnotherapyCouncilAustralia from './images/hypnotherapy-council-australia.jpg';
import Isepp from './images/isepp.png';
import ProfessionalClinicalHypnotherapistsOfAustralia from './images/professional-clinical-hypnotherapists-of-australia.png';

const Memberships = {
  Aapi, AustralianPainSociety, Emdraa, HypnotherapyCouncilAustralia, Isepp, ProfessionalClinicalHypnotherapistsOfAustralia
};

export default Memberships;