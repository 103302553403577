import React from 'react';
import { Fragment, useState } from 'react';
import Section from '../../components/section/section'
import CallToAction from '../../components/call-to-action/call-to-action';
import './resources.scss'
import { Helmet } from 'react-helmet'
import Resource from './resource'
import { resourceList } from './resources-data';
import Dropdown from '../../components/dropdown/dropdown';


const sectionNames = resourceList.map(x => x.name).filter(x => x !== 'General').sort();

const Resources = () => {
  const [activeSections, setActiveSections] = useState(resourceList);
  const [activeSectionName, setActiveSectionName] = useState('all');

  const setSection = (name) => {
    setActiveSectionName(name);
    if (name === 'All') {
      setActiveSections(resourceList);
    } else {
      setActiveSections(resourceList.filter(x => x.name === name));
    }
  }

  const isActive = (name) => {
    return activeSectionName === name;
  }
  return (
    <Fragment>
      <Helmet>
        <title>Resources - chronic pain, trauma, medication and more | Health Couch Psychology</title>
        <meta name="description" content="Resources for key interest areas - chronic pain, trauma, medication, 
          neuroplasticity, relationships, children/adolescents, EMDR, hypnotherapy, and novel coronavirus (COVID-19).">
        </meta>
      </Helmet>
      <Section class="ls section_padding_top_25 section_padding_bottom_60">
        <div className="container ">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="section_header">Resources</h2>
              <p>Extra Information</p>
            </div>
          </div>
          <div className="row hidden-xs">
            <ul class="nav-unstyled resources-nav clearfix" role="tablist">
              <li className={isActive('All') ? 'active' : ''} onClick={() => setSection('All')}>
                All
              </li>
              <li className={isActive('General') ? 'active' : ''} onClick={() => setSection('General')}>
                General
              </li>
              {sectionNames.map(name =>
                <li className={isActive(name) ? 'active' : ''} onClick={() => setSection(name)}>
                  {name}
                </li>
              )}
            </ul>
          </div>
          <div className="row hidden-md hidden-lg hidden-xl">
            <div class="col-xs-12">
              <Dropdown options={(['All', 'General', ...sectionNames]).map(x=> { return {name: x, value: x}})} onChange={(option) => setSection(option)}></Dropdown>
            </div>
          </div>
          <div className="row resources">
            {activeSections.map(section =>
              <Fragment>
                {/* <h3>{section.name}</h3> */}
                {section.items.map(item =>
                  <div className="col-sm-12 col-md-6">
                    <Resource
                      title={item.title}
                      text={item.text}
                      link={item.url}
                      img={item.image}
                      resourceType={item.resourceType}
                    />
                  </div>
                )
                }
              </Fragment>
            )
            }
          </div>
        </div>
      </Section>

      <CallToAction />
    </Fragment>
  );
}
export default Resources