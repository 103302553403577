import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import FindClient from "./find-client";
import IntakeForm from "./intake-form";
import ConsentForm from "./consent-form";
import { setSyntheticLeadingComments } from "typescript";
import { ClientDetailsInterface } from "./types";

const steps = [
  {
    title: "Find Booking",
    id: "find-booking",
    optional: false,
    stepComponent: ({ setActiveStep, setClient }) => (
      <FindClient
        onFindClient={(client) => {
          setClient(client);
          setActiveStep(1);
        }}
      />
    ),
  },
  {
    title: "Intake Form",
    id: "intake-form",
    optional: false,
    stepComponent: ({ client, setSignPage, setActiveStep }) => (
      <IntakeForm
        client={client}
        onSubmit={(x) => {
          console.log("got ", x);
          setSignPage(x["sign_page_url"]);
          setActiveStep(2);
        }}
      />
    ),
  },
  {
    title: "Consent Form",
    id: "consent",
    optional: false,
    stepComponent: ({ signPage }) => <ConsentForm signPageUrl={signPage} />,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

const NewClient = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [client, setClient] = useState<ClientDetailsInterface | null>(null);
  const [signPage, setSignPage] = useState<string | null>(null);
  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step) => {
          const stepProps = {};
          const labelProps: any = {};
          if (step.optional) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          return (
            <Step key={step.id} {...stepProps}>
              <StepLabel {...labelProps}>{step.title}</StepLabel>
              <StepContent>
                {step.stepComponent({
                  client,
                  setActiveStep,
                  setClient,
                  setSignPage,
                  signPage,
                })}
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export default NewClient;
