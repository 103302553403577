import React from "react";
import { Fragment } from "react";
import Section from "../../components/section/section";
import { Helmet } from "react-helmet";
import FaqItem from "./faq-item";
import CallToAction from "../../components/call-to-action/call-to-action";
import CancellationPolicy, {CancellationIfYou} from "../consent/cancellation-policy";

function Faq() {
  return (
    <Fragment>
      <Helmet>
        <title>
          EMDR, child and adolescent therapy, medicare, referrals | Health Couch
          Psychology
        </title>
        <meta
          name="description"
          content="Frequently asked questions, including Medicare, Referrals, Bulk Billing, cancellations, 
          therapeutic approches, and COVID-19 specific questions."
        ></meta>
      </Helmet>
      <Section class="ls section_padding_top_25 section_padding_bottom_60">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="section_header">FAQ</h2>
              <p>Frequently asked questions</p>
            </div>
            <div class="col-sm-12">
              <FaqItem
                title="How many sessions does it take?"
                href="faq-how-many-sessions"
              >
                It depends on individual circumstances, expected timelines will
                be discussed early in therapy.
              </FaqItem>

              <FaqItem
                title="What does a session look like?"
                href="faq-session-look-like"
              >
                We generally begin with a history, identify a treatment focus
                and then work towards a treatment goal over a number of
                sessions.
              </FaqItem>

              <FaqItem
                title="What are the advantages of EMDR therapy over other treatment approaches?"
                href="faq-emdr-benefits"
              >
                EMDR is an evidence based therapy focused on dealing with
                underlying issues rather than managing symptoms.
              </FaqItem>

              <FaqItem
                title="Can I claim this on medicare?"
                href="faq-claim-medicare"
              >
                <p>
                  Medicare now covers 10 sessions per calendar year, first 6 with a referral, then 4 after a review and re-referral. 
                  Additional Medicare sessions (10) due to COVID no longer apply.
                </p>
                <p>
                  Medicare requires that the referral be received by Health Couch before your appointment starts.
                  It doesn't matter who the referral is made out to - you get to choose who you want to see.
                  Health Couch will process your Medicare claim after you have paid in full.
                </p>
              </FaqItem>

              <FaqItem title="Do I need a GP referral?" href="faq-gp-referral">
                Yes if you wish to claim medicare rebates otherwise no.
              </FaqItem>

              <FaqItem
                title="Are you an approved provider for Government services such as NDIS, TAC and workcover?"
                href="faq-approved-gov-provider"
              >
                Not at this time.
              </FaqItem>

              <FaqItem title="Do you bulk bill?" href="faq-bulk-bill">
                No. Bulk billing specifies particular treatment strategies.
                Health Couch uses additional psychological treatments not
                available with bulk billing.
              </FaqItem>

              <FaqItem
                title="How do I cancel my appointment?"
                href="faq-cancellations"
              >
                <p>
                  A deposit is payable at the time of booking. 
                </p>
                
                <CancellationPolicy/>
                
              </FaqItem>

              <FaqItem
                title="What if I don't show up and forget to provide notice?"
                href="faq-no-show"
              >
                <CancellationIfYou/>
              </FaqItem>

              <FaqItem
                title="What if my appointment goes over time?"
                href="faq-over-time"
              >
                Health Couch will advise you close to the end of your allotted
                time. If you wish to continue, additional time is generally
                charged per 15 minute increment.
              </FaqItem>

              <FaqItem title="Do you work with children?" href="faq-children">
                Yes. Principal psychologist Susan Henderson has a working with
                children check and 30+ years of experience working with children
                and adolescents.
              </FaqItem>

              <FaqItem title="Does the cost include GST?" href="faq-tax">
                No; you aren't generally liable for GST. GST doesn't apply for
                Health Services unless third parties such as employers are
                paying. In which case, fees are exclusive of GST.
              </FaqItem>
              <FaqItem title="How do I pay?" href="faq-covid-19-payment">
                We prefer online bookings, a deposit is required at the time of
                booking and we will send you an invoice for any residual after
                the appointment.
              </FaqItem>
              <FaqItem
                title="Can I do this remotely via telehealth?"
                href="faq-covid-19-telehealth"
              >
                Yes, Health Couch has been set up as a purely Telehealth practice. 
                Video appointments are conducted via Coviu, a secure, encrypted service. 
                Health Couch uses online secure EMDR software - REMDR (remote EMDR, "Rem doctor") - 
                to achieve similar results to in person sessions, and hypnotherapy is similarly adapted to suit video. 
              </FaqItem>
            </div>
          </div>
        </div>
      </Section>
      <CallToAction />
    </Fragment>
  );
}
export default Faq;
