import React from 'react';
import './dropdown.scss'

const DropdownOption = ({children, onSelect, value}) => {
  onSelect = onSelect ? onSelect : () => {};
  return (
    <li onClick={(e) => onSelect(value)}>{children}</li>
  );
};

export default DropdownOption;