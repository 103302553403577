import React from 'react';
import { Fragment } from 'react';
import Section from '../../components/section/section'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';
import CallToAction from '../../components/call-to-action/call-to-action';

function ChronicPain() {
  return (
    <Fragment>
      <Helmet>
        <title>Evidence-based pain treatment | Health Couch Psychology</title>
        <meta name="description" content="We help you dentify pain and turn it down so it doesn't become crippling"></meta>
      </Helmet>
      <Section class="ls section_padding_top_25 section_padding_bottom_60">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="section_header">Chronic pain and phantom limb</h2>
              {/* <p>Anxiety, Depression and Stress</p> */}
            </div>
            <div className="col-sm-12">
              <p>We teach people how to identify pain and use psychological techniques to change their
                perception and experience of pain, and learn to regulate it so it doesn't become crippling. </p>

              <p>The International Association for the Study of Pain (IASP) defines pain as “an unpleasant
              sensory and emotional experience associated with actual or potential tissue damage, or
              described in terms of such damage.” Pain is always subjective. Your personality, attitudes,
                and beliefs can strongly affect your experience of pain. </p>

              <p>The purpose of acute pain is to act as a flag and tell you that you’re injured or will soon be.
              Sometimes this gets stuck in the threat centre of the brain and we keep feeling pain after the
              threat has passed - the pain becomes chronic. Threats can be physical or emotional - sometimes
                an emotional threat can be felt as physical, or vice versa. </p>

              <p>Physical pain can be felt as a generalised or localised. It can also move (e.g. your hand hurts
              one day, your leg the next) or be felt in an area that is no longer there (phantom limb).
                This is because the pain flag is coming from your brain, not the injury site. </p>

              <p>We use therapies like <Link to="/therapies/emdr">EMDR</Link> and <Link to="/therapies/hypnotherapy">Hypnotherapy</Link> to help</p>
              <ul>
                <li>you identify the cause of the pain and processing it, removing the need for the pain flag</li>
                <li>your brain remember and reactivate how you felt before the pain</li>
                <li>you identify pain and turn it down so it doesn't become crippling</li>
              </ul>
              <p>More information is available at</p>
              <ul>
                <li><a target="_new" href="https://www.iasp-pain.org/">International Association for the Study of Pain</a></li>
                <li><a target="_new" href="https://www.apsoc.org.au/">Australian Pain Society</a></li>
              </ul>
            </div>
          </div>
        </div>
      </Section>

      <CallToAction />
      {/* <Resources></Resources> */}

    </Fragment>
  );
}
export default ChronicPain