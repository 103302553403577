import React from 'react';
import {Fragment} from 'react';
import './call-to-action.scss';
import {Link} from 'react-router-dom';
import Section from '../section/section';
import BookButton from './book-button'
function CallToAction(props) {
  return (
    <Fragment>
      <Section class="cs call-to-action">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              {/* <h2 class="section_header with_icon">Need to talk?</h2> */}
              <h3 className="section_header"> 
                Ready to book? Unsure? 
              </h3>
              <p className="subtext">
                We offer a free 15 minute telephone discussion; find out more about Health Couch and see if we're right for you.
              </p>
              <p>
                <BookButton bookingUrl={props.bookingUrl}/>
                <span className="button-break-or" style={{padding: 10}}>or</span>
                <Link to='/contact' className="theme_button color4">Send enquiry</Link>
              </p>
            </div>
          </div>
        </div>
      </Section>
    </Fragment>
  );
}
export default CallToAction;
