import React from 'react';
import { Fragment } from 'react';
import Section from '../../components/section/section'
import { Helmet } from 'react-helmet'
import CallToAction from '../../components/call-to-action/call-to-action';
import './practitioners.scss'
import susan from "../../susan-v2.png";

function Practitioners() {
	return (
		<Fragment>
			<Helmet>
				<title>Practitioners - Susan Henderson | Health Couch Psychology</title>
				<meta name="description" content="Our principal psychologist, Susan Henderson, has 30+ years experience in  
				EMDR, Hypnotherapy, and Psychotherapy. Passionate about helping people process trauma and manage chronic pain, 
				and on using effective therapies to treat underlying problems rather than just managing symptoms.">
				</meta>
			</Helmet>
			<Section className="ls section_padding_top_25 section_padding_bottom_60">
				<div className="container">
					<div className="row">
						<div className="col-sm-12 text-center">
							<h2 className="section_header">Our Practitioners</h2>
							<p>More on our people</p>
						</div>
					</div>
				</div>
			</Section>
			<Section className="ls section_padding_top_25 section_padding_bottom_60">
				<div className="container practitioners">
					<div className="row">
						<div className="col-md-3">
							<div className="vertical-item content-padding text-center">
								<div className="item-media">
									<img src={susan} alt="" className="practitioner-image" />
								</div>
							</div>
						</div>

						<div className="col-md-9">
							<h2 className="highlight">Susan Henderson</h2>
							<p className="credentials">DipT(MCAE), BA(UNE), BAHons, MA(Macq), DipClinHyp, CH, HCARH </p>
							<p>
								I've been in private practice as a psychologist and hypnotherapist for more than 30 years,
								working with all age groups. My specific work interests are trauma (in its many forms)
								and pain; it's all about the power of the mind and the wonder that is our brain.
							</p>
              <h3>Background</h3>
							<p>
								I began my career as a school teacher and then moved into school psychology. I
								wanted to help kids. I knew that many learning and social problems were the result of
								other things happening in their lives - and vice versa - and also wanted to help them
								challenge their assumptions about themselves and their abilities. I now work with all
								age groups and often we're addressing childhood:
								<p></p>
                <ul>
                  <li>what happens to us as kids forms the basis of our reactions as adults; </li>
                  <li>we are who we are because of how we reacted to what we experienced; </li>
                  <li>nobody does something for no reason.</li>
                </ul>
							</p>
              <h3>Current Focus</h3>
							<p>
								Private practice gives me the opportunity to do what I do best and am passionate about:
								using effective therapies that empower people to make changes that turn lives around.
								We're not meant to be happy all the time - sometimes it's not appropriate - but you
								don't have to be stuck in a rut either.
							</p>
							<p>
								I'm passionate about treating underlying problems rather than just managing symptoms,
								and my therapeutic approaches reflect this. I originally trained in hypnosis because it
								made no sense to me to be a psychologist and not use hypnosis - and I've found it to be
								highly successful, enough that I'm still using it after 25 years! I've also used EMDR,
								a highly effective evidence-based trauma therapy for almost 20 years. These complement
								other psychotherapies, such as Parts Therapy, Schema Therapy and Play Therapy.
							</p>

							<ul className="nav nav-tabs topmargin_40" role="tablist">
								<li className="active">
									<a href="#tab1" role="tab" data-toggle="tab">Training</a>
								</li>
								<li>
									<a href="#tab2" role="tab" data-toggle="tab">Memberships</a>
								</li>
							</ul>

							<div className="tab-content top-color-border bottommargin_40">

								<div className="tab-pane fade in active" id="tab1">
									<h3 className="topmargin_0">Qualifications and certificates:</h3>
									<ul>
										<li>BA (University of New England) - psychology/special education </li>
										<li>BAHons (Macquarie University) - psychology </li>
										<li>MA (Macquarie University) - psychology - counselling - school counselling</li>
										<li>Dip Teach (Mitchell CAE) - general primary - educational psychology/special education</li>
										<li>DipClinHyp (Diploma Clinical Hypnotherapy - NSW School of Hypnotic Sciences)</li>
										<li>CH (Certified Hypnotherapist - National Guild of Hypnotists, USA)</li>
										<li>CH (Certified Hypnotherapist - Dave Elman Hypnosis Institute, USA)</li>
										<li>Rapid Intervention Hypnotherapy (Achieve Now Institute Michigan, USA)</li>
										<li>Instant &amp; Rapid Hypnosis (Atlantic Hypnosis Institute, USA)</li>
										<li>Medical Hypnotherapy (the Hypnotherapy Training Institute of Britain)</li>
										<li>EMDR Levels I &amp; II (Anthony T Smith &amp; Sigmund Burzynski, EMDR Institute)</li>
									</ul>
									<h3 className="topmargin_0">Hypnosis trainers:</h3>
									<ul>
										<li>Larry Elman</li>
										<li>Cheryl Elman</li>
										<li>Norm Caldwell</li>
										<li>Sean Michael Andrews / Don Patterson</li>
										<li>Dr John Butler</li>
									</ul>

								</div>

								<div className="tab-pane fade" id="tab2">
									<h3 className="topmargin_0">Registrations:</h3>
									<ul>
										<li>registered psychologist (AHPRA - Australian Health Practitioner Registration Agency)</li>
										<li>registered Medicare Health Provider</li>
										<li>HCA registered Hypnotherapist (HCARH - Hypnotherapy Council of Australia)</li>
									</ul>
									<h3 className="topmargin_0">Memberships:</h3>
									<ul>
										<li>EMDR Association Australia (EMDRAA)</li>
										<li>Professional Clinical Hypnotherapists of Australia (PCHA)</li>
										<li>Australian Association of Psychologists Inc (AAPi)</li>
										<li>Australian Pain Society (APS)</li>
										<li>Dave Elman Hypnosis Institute Alumni (DEHI Alumni)</li>
										<li>Marcé Society (International &amp; Australasian Branch)</li>
										<li>Australian Society for Traumatic Stress Studies (ASTSS)</li>
										<li>Healthy Skepticism</li>
										<li>International Society for Ethical Psychology and Psychiatry (ISEPP)</li>
									</ul>
								</div>
							</div>
						</div>

					</div>
				</div>
			</Section>
			<CallToAction />
		</Fragment>
	);
}
export default Practitioners