
import React from 'react';

const Therapy = ({register, errors, watch}) => {
  const therapy_specific = watch("therapy_specific");
  const therapy_sought = watch("therapy_sought");
  return ( 
    <div className="col-sm-12">
      <h2>Specific therapy</h2>
      <div className="form-group">
        <label htmlFor="therapy_specific">Did you come seeking a specific therapy?</label>
        <select className="form-control" name="therapy_specific" id="therapy_specific" ref={register({ required: true })}>
          <option value="">- Please select</option>
          <option>Yes</option>
          <option>No</option>
        </select>
        <div className="error">{errors.therapy_specific ? 'Please select yes/no' : ''}</div>
      </div>
      { therapy_specific === "Yes" ? (
      <div className="form-group">
        <label htmlFor="therapy_sought">What therapy are you seeking?</label>
        <select className="form-control" name="therapy_sought" id="therapy_sought" ref={register({ required: therapy_specific === "Yes" })}>
          <option value="">- Please select</option>
          <option>EMDR</option>
          <option>Clinical Hypnotherapy</option>
          <option>Other</option>
        </select>
        <div className="error">{errors.therapy_sought ? 'Please specify a therapy' : ''}</div>
      </div>
      ) : '' }
      { therapy_specific === "Yes" && therapy_sought === "Other" ? (
      <div className="form-group">
        <label htmlFor="therapy_sought_other">Please specify:</label>
        <input type="text" id="therapy_sought_other" name="therapy_sought_other" ref={register({ required: therapy_specific === "Yes" && therapy_sought === "Other" })}/>
        <div className="error">{errors.therapy_sought_other ? 'Please specify a therapy' : ''}</div>
      </div>
      ) : '' }
    </div>
  );
};

export default Therapy;