
import React, {Fragment} from 'react';

const Child = ({register, errors, watch}) => {
  const child_appointment_for_child = watch("child_appointment_for_child");
  
  return ( 
    <div className="col-sm-12">
      <h2>Is this appointment for a child?</h2>
      <div className="form-group">
        <label htmlFor="child_appointment_for_child">Is this appointment for a child under the age of 18?</label>
        <select className="form-control" name="child_appointment_for_child" id="child_appointment_for_child" ref={register({ required: true })}>
          <option value="">- Please select</option>
          <option>No</option>
          <option>Yes</option>
        </select>
        <div className="error">{errors.child_appointment_for_child ? 'Please select an option' : ''}</div>
      </div>
      { child_appointment_for_child === "Yes" ? (
      <Fragment>
      <div className="form-group">
        <label htmlFor="child_school_and_grade">What is the child's school and grade?</label>
        <input type="text" id="child_school_and_grade" name="child_school_and_grade" ref={register({ required: child_appointment_for_child === "Yes" })}/>
        <div className="error">{errors.child_school_and_grade ? 'Please provide the child\'s school name and year level' : ''}</div>
      </div>
      <div className="form-group">
        <label htmlFor="child_responsible_adult_name">What is your full name?</label>
        <input type="text" id="child_responsible_adult_name" name="child_responsible_adult_name" ref={register({ required: child_appointment_for_child === "Yes" })}/>
        <div className="error">{errors.child_responsible_adult_name ? 'Please provide your full name' : ''}</div>
      </div>
      <div className="form-group">
        <label htmlFor="child_responsible_adult_relationship">What is your relationship to the child?</label>
        <input type="text" id="child_responsible_adult_relationship" name="child_responsible_adult_relationship" ref={register({ required: child_appointment_for_child === "Yes" })}/>
        <div className="error">{errors.child_responsible_adult_relationship ? 'Please provide your relationship to the child' : ''}</div>
      </div>
      <div className="form-group">
        <label htmlFor="child_court_procedures">Court procedures</label>
        <textarea aria-required="true" rows="2" cols="45" id='child_court_procedures' name='child_court_procedures' ref={register()} className="form-control" placeholder="Please provide details of any court procedures (if any)"></textarea>
      </div>
      </Fragment>
      ) : '' }
    </div>
  );
};

export default Child;