import React from 'react';
import {Fragment} from 'react';
import Section from '../../components/section/section'
// import {Link} from 'react-router-dom'
import { Helmet } from 'react-helmet'

function PrivacyPolicy(){
  return (
    <Fragment>
      <Helmet>
        <title>Health Couch Psychology | Privacy Policy</title>
        <meta name="description" content="Our privacy policy."></meta>
      </Helmet>
      <Section class="ls section_padding_top_25 section_padding_bottom_60">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="section_header">Privacy Policy</h2>
              <p>We take your privacy seriously</p>
            </div>
            <div class="col-sm-12">
              <h2>Privacy Policy</h2>
              <p>
                Your privacy is important to us. We respect your privacy regarding any information we may collect from you across our website, 
                &nbsp;<a href="https://health-couch.com.au">health-couch.com.au</a>, and in any sessions we may have with you.
              </p>
              
              <p>
                We only ask for personal information when we need it to provide a service to you. We collect it by fair and lawful means, 
                with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.
              </p>
              
              <p>
                We only retain collected information for as long as necessary to provide you with your requested service and comply with 
                relevant legislation. What data we store, we protect within commercially acceptable means to prevent loss and theft, as 
                well as unauthorised access, disclosure, copying, use or modification.
              </p>
              
              <p>
                We don’t share any personally identifying information publicly or with third-parties, except when required to by law.
              </p>
              
              <p>
                Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content
                and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.
              </p>
              
              <p>
                You are free to refuse our request for your personal information, with the understanding that we may be unable to provide 
                you with some of your desired services.
              </p>
              
              <p>
                Your continued use of our website and any sessions you have with us will be regarded as acceptance of our practices around 
                privacy and personal information. If you have any questions about how we handle user data and personal information, feel 
                free to <a href="https://health-couch.com.au/contact">contact us.</a>
              </p>
              
              <p>
                This policy is effective as of 1 April 2020.  
              </p>
            </div>
          </div>
        </div>
      </Section>
    </Fragment>
  );
}
export default PrivacyPolicy