import React from 'react';
import { Fragment } from 'react';
import Section from '../../components/section/section'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import CallToAction from '../../components/call-to-action/call-to-action';

function ReducingMedication() {
  return (
    <Fragment>
      <Helmet>
        <title>Safely reduce medication | Health Couch Psychology</title>
        <meta name="description" content="In consultation with your GP, gradually and systematically reduce your reliance on medication. 
          Manage withdrawal symptoms."></meta>
      </Helmet>
      <Section class="ls section_padding_top_25 section_padding_bottom_60">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="section_header">Safely reducing medication</h2>
              <p>In consultation with your GP or other health professional</p>
              {/* <p></p> */}
            </div>
            <div className="col-sm-12">
              <p>Many psychotropic medications are addictive.
              Addiction or withdrawal can be psychological or physical, or both. Individual experiences vary. </p>

              <p>Ceasing or reducing medication needs to be carefully managed by qualified
                professionals, and should not be attempted alone or cold turkey.</p>


              <p>In consultation with your GP or other medical professional, we work with you to gradually
              and systematically  reduce your reliance on medication and to manage symptoms such as
                pain, insomnia, anxiety, depression and stress. The emphasis is on monitoring your reactions and adjusting accordingly. </p>

              <p>We use therapies such as <Link to="/therapies/emdr">EMDR</Link> and
              <Link to="/therapies/hypnotherapy"> Hypnotherapy</Link> to treat the issue that was
              being medicated, manage withdrawal symptoms and train the brain to reduce medication dependency.  </p>
            </div>
          </div>
        </div>
      </Section>

      <CallToAction />
      {/* <Resources></Resources> */}

    </Fragment>
  );
}
export default ReducingMedication