  import React from 'react';
import {Fragment} from 'react';
import './covid-19.scss';
import Section from '../../components/section/section'
// import Resources from './resources'
import covid from '../../covid.jpg'
// import {Link} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import CallToAction from '../../components/call-to-action/call-to-action';
// import FreeConsult from '../../components/free-consult/free-consult';
function Covid19() {
  return (
    <Fragment>
      <Helmet>
        <title>Phone and video sessions during the COVID-19 outbreak | Health Couch Psyhology</title>
        <meta name="description" content="Health Couch is offering phone and video sessions during the COVID-19 outbreak. 
          If you or your family are having trouble coping, you can get in touch for a telehealth session today."></meta>
      </Helmet>
      <Section class="ls">
        <div style={{textAlign: 'center'}}>
          <img alt={"COVID-19 Virus"} src={covid} width="300px"/>
        </div>
      </Section>

      <Section class="ls section_padding_top_30 section_padding_bottom_30">
        <div className="container">
          <div className="row-content clearfix">
            <div className="col-sm-12 text-center">
              <h2 className="section_header">Novel Coronavirus / COVID-19</h2>
            </div>
            <div className="col-sm-12">
              <p>
                Feelings of worry and unease are normal responses to stressful events,
                but it is important to manage our stress so that it doesn't become overwhelming.
              </p>
              <p>
                We've been stuck in closer quarters than usual - kids at home while parents work, 
                working from home, and many cut off from friends, family, and normal social life. 

                You may also be wondering how to explain this to kids who are worried or anxious,
                having trouble focusing, acting up, bed wetting or being fussy about eating.
              </p>
              <p>
                If you or your family are having trouble coping, you can get in touch for a telehealth session today. 
                Video appointments are via Coviu which is a secure, encrypted service. 
              </p>
            </div>
          </div>
        </div>
      </Section>

      <CallToAction/>
      {/* <Resources></Resources> */}
    </Fragment>
  );
}

export default Covid19;
