import React, { Fragment, useState } from "react";
import "./header.scss";
import { Link, NavLink, useLocation } from "react-router-dom";
// import {useState, useCallback} from 'react';
// import {Component} from 'react';
import logo from "../../logo-wide-50px.png";

const Header: React.FunctionComponent = () => {
  const toggleMenu = () => {
    setState((state) => ({ ...state, mobileActive: !state.mobileActive }));
  };
  const location = useLocation();
  const [state, setState] = useState({
    mobileActive: false,
    hidden: location.pathname.toLowerCase() === "/emdr",
  });

  const navActive = ({ isActive }: { isActive: boolean }) =>
    isActive ? "active" : "";

  return (
    <header
      className={
        "header page_header header_white toggler-xs-right" +
        (state.mobileActive ? " mobile-active" : "")
      }
    >
      <div className="">
        {!state.hidden ? (
          <Link to="/" className="logo">
            <img alt={"Health Couch logo"} src={logo} />
          </Link>
        ) : (
          <a href="/" className="logo">
            <img alt={"Health Couch logo"} src={logo} />
          </a>
        )}
      </div>
      <div className="menu-content">
        {!state.hidden ? (
          <nav className="mainmenu_wrapper">
            <ul className="mainmenu nav sf-menu" onClick={() => toggleMenu()}>
              <li>
                <NavLink className={navActive} to="/">
                  Home
                </NavLink>
              </li>

              <li>
                <NavLink className={navActive} to="/news">
                  News
                </NavLink>
              </li>

              <li>
                <NavLink className={navActive} to="/covid-19">
                  COVID-19
                </NavLink>
              </li>

              <li>
                <NavLink className={navActive} to="/faq">
                  FAQ
                </NavLink>
              </li>

              <li>
                <NavLink className={navActive} to="/practitioners">
                  Practitioners
                </NavLink>
              </li>

              <li>
                <NavLink className={navActive} to="/resources">
                  Resources
                </NavLink>
              </li>

              <li>
                <NavLink className={navActive} to="/bookings">
                  Book Now
                </NavLink>
              </li>

              <li>
                <NavLink className={navActive} to="/contact">
                  Contact
                </NavLink>
              </li>
            </ul>
          </nav>
        ) : (
          <Fragment />
        )}
      </div>
      <div className="header-phone-number">
        <a href="tel:0416057570">
          <i className="fa fa-phone"></i> 0416 057 570
        </a>
        {!state.hidden ? (
          <i
            className="fa fa-bars visible-xs menu-toggle"
            onClick={() => toggleMenu()}
          ></i>
        ) : (
          <Fragment />
        )}
      </div>
    </header>
  );
};

export default Header;
