import React from "react";
import { Fragment } from "react";
import "./telehealth-banner.scss";
import Section from "../section/section";
function TelehealthBanner() {
  return (
    <Fragment>
      <Section class="cs">
        <div className="telehealth-banner clearfix">
          <div className="col-sm-12 col-md-8 col-md-offset-2">
            <h3 className="section_header">
              Telehealth appointments <span class="hidden-xs">only</span>
            </h3>
            <p>
              Medicare covers 10 sessions per calendar year.
            </p>
          </div>
        </div>
      </Section>
    </Fragment>
  );
}
export default TelehealthBanner;
