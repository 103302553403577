
import React from 'react';
import {useState} from 'react';
import { useForm } from "react-hook-form";
import axios from 'axios';
import './find-client.scss';
import {
  DatePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';

const FindClient = ({onFindClient}) => {

  const previousAttempt = localStorage.getItem('new-client.identity');
  let initialValues = {};
  if(previousAttempt){
    initialValues = JSON.parse(previousAttempt);
  }

  const [progress, setProgress] = useState(false);
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState(false);
  const [selectedDate, setSelectedDate] = useState(initialValues.date_of_birth);
  
  
  

  const { register, handleSubmit, errors, watch, setValue } = useForm({defaultValues: initialValues})
  const formData = watch();

  const saveFormData = (date_of_birth) => {
    let d = {...formData};
    if(date_of_birth) d.date_of_birth = date_of_birth;
    localStorage.setItem('new-client.identity', JSON.stringify(d));
  }

  const setDOB = (dob) => {
    setValue('date_of_birth', dob.format('YYYY-MM-DD'));
    setSelectedDate(dob);
    saveFormData(dob.format('YYYY-MM-DD'));
  }
  

  const findClient = (data) => {
    if(Object.keys(errors).length) return;
    setProgress(true);
    setComplete(false);
    setError(false);
    saveFormData();
    axios.post(
      '/api/identify-client', 
      // 'https://health-couch.com.au/api/identify-client', 
      // 'http://localhost:5000/api/identify-client', 
      data
    ).then(res => {
      setComplete(res.data.found);
      setError(!res.data.found);
      setProgress(false);
      if(res.data.found) {
        onFindClient(res.data);
      }
    }).catch(err => {
      console.log('Err', err)
      setProgress(false);
      setError(true);
    })
  }

  return (
    
    <form onSubmit={handleSubmit(findClient)} className='find-client'>
      <div className="container">
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2 form-group">
            <label htmlFor="last_name">Last Name</label>
            <input type="text" className="form-control" disabled={complete} name="last_name" id="last_name" placeholder="Last Name" ref={register({ required: true })} />
            <div className="error">{errors.last_name ? 'Last name is required' : ''}</div>
          </div>

          <div className="col-sm-8 col-sm-offset-2 form-group">
            <label htmlFor="last_name">Email</label>
            <input type="text" className="form-control" disabled={complete} name="email" id="email" placeholder="Email" ref={register({ required: true })} />
            <div className="error">{errors.last_name ? 'Email address is required' : ''}</div>
          </div>

          <div className="col-sm-8 col-sm-offset-2 form-group">
            <label htmlFor="date_of_birth">Date of birth</label>
            {/* <input type="text" className="form-control" disabled={complete} name="date_of_birth" id="date_of_birth" placeholder="Date of birth" ref={register({ required: true })} /> */}
            <KeyboardDatePicker
              clearable
              value={selectedDate}
              placeholder="01/01/2000"
              onChange={date => setDOB(date)}
              maxDate={new Date()}
              format="DD/MM/YYYY"
              className="hidden-xs"
            />
            <DatePicker 
              disableFuture
              openTo="year"
              format="DD/MM/YYYY"
              // label="Date of birth"
              onChange={date => setDOB(date)}
              views={["year", "month", "date"]}
              value={selectedDate}
              className="hidden-sm hidden-md hidden-lg"
            />
            <input type="hidden" name="date_of_birth" ref={register({ required: true })}/>
            <div className="error">{errors.date_of_birth ? 'Date of birth is required' : ''}</div>
          </div>
        </div>
        <div className="row find-my-record">
          <div className="col-sm-offset-2 col-sm-8">
            { progress ? (
            <div>
              <i className='fa fa-spinner fa-spin'></i> Looking for your booking
            </div>
            ) : '' }

            { complete ? ( 
            <div className='alert alert-info'>
              <p><strong>We found you!</strong></p>
              <p>We successfully confirmed your booking details.</p>
            </div>
            ) : '' }

            { error ? (
            <div className='alert alert-warning'>
              <p><strong>Uh oh</strong></p>
              <p>We were unable to find your booking details based on the details you provided. 
                Please make sure they match exactly with what you entered when creating your booking.
                You can find this in your confirmation email.
              </p>
            </div>
            ) : '' }
            { ! complete ? (
            <div className="col-sm-12 text-center">
              <button type="submit">Find my record</button>
            </div>
            ) : ''
            }
          </div>
        </div>  
      </div>
    </form>
  )
}
export default FindClient;