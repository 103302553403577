
import React from 'react';

const HowFound = ({register, errors, watch}) => {
  const how_found = watch("how_found");
  
  return ( 
    <div className="col-sm-12">
      <h2>How you found us</h2>
      <div className="form-group">
        <label htmlFor="how_found">Where did you hear about us?</label>
        <select className="form-control" name="how_found" id="how_found" ref={register({ required: true })}>
          <option value="">- Please select</option>
          <option>Friend or family member</option>
          <option>GP referral</option>
          <option>Google / web search</option>
          <option>Third party website</option>
          <option>Other</option>
        </select>
        <div className="error">{errors.how_found ? 'Please select an option' : ''}</div>
      </div>
      { how_found === "Third party website" ? (
      <div className="form-group">
        <label htmlFor="how_found_website">What website did you find us through?</label>
        <input type="text" id="how_found_website" name="how_found_website" ref={register({ required: how_found === "Third party website" })}/>
        <div className="error">{errors.how_found_website ? 'Please specify the website' : ''}</div>
      </div>
      ) : "" }
      { how_found === "Other" ? (
      <div className="form-group">
        <label htmlFor="how_found_other">Please specify:</label>
        <input type="text" id="how_found_other" name="how_found_other" ref={register({ required: how_found === "Other" })}/>
        <div className="error">{errors.how_found_other ? 'Please let us know how you found us' : ''}</div>
      </div>
      ) : '' }
    </div>
  );
};

export default HowFound;