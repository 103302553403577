
import React from 'react';

const WhySoughtHelp = ({register, errors, watch}) => {
  const problem_why_sought_help = watch("problem_why_sought_help");
  return ( 
    <div className="col-sm-12">
      <h2>Why you're here</h2>
      <div className="form-group">
        <label htmlFor="problem_why_sought_help">Why have you sought help from Health Couch?</label>
        <select className="form-control" name="problem_why_sought_help" id="problem_why_sought_help" ref={register({ required: true })}>
          <option value="">- Please select</option>
          <option>Chronic pain or phantom limb</option>
          <option>Anxiety, depression or stress</option>
          <option>Fibromyalgia or chronic fatigue</option>
          <option>PTSD</option>
          <option>Child and adolescent therapy</option>
          <option>Safely reducing medication</option>
          <option>Anxiety or panic attacks</option>
          <option>OCD</option>
          <option>Other</option>
          <option>N/A</option>
        </select>
        <div className="error">{errors.problem_why_sought_help ? 'Please select an option' : ''}</div>
      </div>
      { problem_why_sought_help === "Other" ? (
      <div className="form-group">
        <label htmlFor="problem_why_sought_help_other">Please specify</label>
        <input type="text" id="problem_why_sought_help_other" name="problem_why_sought_help_other" ref={register({ required: problem_why_sought_help === "Other" })}/>
        <div className="error">{errors.problem_why_sought_help_other ? 'Please let us know why you are seeking help' : ''}</div>
      </div>
      ) : ''}
    </div>
  );
};
export default WhySoughtHelp;